import { makeStyles } from "@material-ui/core/styles";

export const indexStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "50px",
    paddingBottom: "50px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "30px",
      paddingBottom: "30px",
    },
  },
  marginGridItem: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  container: {
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "5px",
  },
  menuList: {
    paddingTop: "0px",
    paddingBottom: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-around",
    },
  },
  menuItem: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRight: `1px solid ${theme.palette.MID_GREY}`,
      width: "25%",
      textAlign: "center",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "30%",
    },
  },
  menuItemFirst: {
    paddingTop: "15px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRight: `1px solid ${theme.palette.MID_GREY}`,
      width: "25%",
      paddingTop: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "20%",
    },
  },
  menuItemLast: {
    paddingTop: "10px",
    paddingBottom: "15px",
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
      paddingBottom: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "20%",
    },
  },
  menuItemText: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "center",
      fontSize: "11px",
    },
  },
  loadingContainer: {
    height: "18px",
  },
  loadingIcon: {
    marginLeft: "5px",
  },
  checkIcon: {
    fontSize: "18px",
    marginLeft: "5px",
    color: "#12a369",
  },
  remainingIcon: {
    fontSize: "18px",
    marginLeft: "5px",
    color: "#c99f36",
  },
}));
