import { withStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";

export const BlueCheckBox = withStyles((theme) => ({
  root: {
    "& svg": {
      width: "22px",
      height: "22px"
    },
    "&$checked": {
      color: theme.palette.primary.dark,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);
