const capitalizeFirstLetter = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function formatNumber(number) {
  const newNumber = number.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  return newNumber
}

// for feedback msg
const feedbackMsgClose = (event, reason, setOpen) => {
  if (reason === "clickaway") {
    return;
  }
  setOpen(false);
};

module.exports = {
  capitalizeFirstLetter,
  formatDate,
  formatNumber,
  feedbackMsgClose
};
