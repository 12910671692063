import React, { useState } from "react";
import { Switch } from "react-router-dom";
import { Grid, Box, Typography } from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import UserInfo from "./EditUserInfo";
import ChangePassword from "./ChangePassword";
import { indexStyles } from "./styles";
import { useSelector } from "react-redux";
import PersonIcon from "@material-ui/icons/Person";
import PrivateRoute from "../../auth/PrivateRoute";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function Profile() {
  const styleClasses = indexStyles();
  const history = useHistory();
  const isTablet = useMediaQuery({ query: "(max-width: 900px)" });

  const [selectedIndex, setSelectedIndex] = useState(0);

  const displayProfileInfo = (e, index) => {
    setSelectedIndex(index);
    history.push("/profile/info");
  };
  const displayChangePassword = (e, index) => {
    setSelectedIndex(index);
    history.push("/profile/change-password");
  };

  return (
    <div className={styleClasses.root}>
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={2}>
          <div className={styleClasses.container}>
            <List className={styleClasses.list}>
              <ListItem
                button
                onClick={(e) => displayProfileInfo(e, 0)}
                selected={selectedIndex === 0}
              >
                <ListItemIcon className={styleClasses.listIcon}>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  className={styleClasses.listText}
                  primary="Profile info"
                />
              </ListItem>
            </List>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div>
            <Switch>
              <PrivateRoute path="/profile/info" component={UserInfo} />
            </Switch>
          </div>
        </Grid>
        <Grid item xs={12} md={2} />
      </Grid>
    </div>
  );
}

export default Profile;
