import * as actions from "../actions/actionTypes";
import isEmpty from "is-empty";

const initialState = {
  preQAnswers: [],
};

function preQReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_PRE_QUESTIONS:
      return {
        ...state,
        preQAnswers: payload,
      };
    default:
      return state;
  }
}

export default preQReducer;
