import { makeStyles } from "@material-ui/core/styles";

export const indexStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.DARK_BLUE,
    paddingBottom: "50px",
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imageContainer: {
    marginBottom: "20px",
  },
  image: {
    height: "100px",
  },
  text: {
    color: theme.palette.WHITE,
    textAlign: "center",
    fontSize: "20px",
  },
  button: {
    textTransform: "none",
    fontSize: "16px",
  },
  homeButton: {
    color: `${theme.palette.DARK_YELLOW}`,
    border: `2px solid ${theme.palette.DARK_YELLOW}`,
    borderRadius: "50px",
    transition: "0.5s",
    padding: "3px 20px",
    marginTop: "15px",
    "&:hover": {
      color: theme.palette.DARK_BLUE,
      backgroundColor: theme.palette.DARK_YELLOW,
      transition: "0.5s",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3px 15px",
      fontSize: "12px",
    },
  },
}));
