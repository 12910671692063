import { makeStyles } from "@material-ui/core/styles";

export const indexStyles = makeStyles((theme) => ({
  loadingRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    marginTop: "-50px",
  },
  paperContainer: {
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "10px",
    paddingTop: "30px",
    paddingBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "20px",
    },
  },
  titleContainer: {
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "10px",
    paddingTop: "20px",
    paddingBottom: "20px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "15px",
    },
  },
  greyText: {
    color: theme.palette.DARK_GREY,
    fontSize: "14px",
  },
  container: {
    width: "85%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
    },
  },
  lastContainer: {
    marginBottom: "0px",
  },
  mainTitle: {
    fontSize: "17px",
    marginBottom: "10px",
    color: theme.palette.BLACK,
    fontFamily: "Roboto-Medium",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  linkToAboutStudy: {
    color: "#5e5ee6",
    cursor: "pointer",
  },
  secTitle: {
    color: theme.palette.DARK_GREY,
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      fontSize: "15px",
      marginBottom: "5px",
    },
  },
  postContainer: {
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "10px",
    padding: "30px 30px",
    marginTop: "15px",
    marginBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
    },
  },
  post: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  divider: {
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6px",
    },
  },
  chartContainer: {
    marginTop: "40px",
    marginBottom: "40px",
  },
}));

export const percentageStyles = makeStyles((theme) => ({
  root: {
    marginTop: "10px",
  },
  agreeContainer: {
    marginTop: "20px",
  },
  formControl: {
    marginTop: "10px",
  },
  mainText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  ratingText: {
    fontSize: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  radio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  radioLabel: {
    fontSize: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  irrelevantTextContainer: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  irrelevantText: {
    fontSize: "15px",
  },
  textFieldContainer: {
    marginBottom: "15px",
  },
  inputField: {
    width: "100%",
    marginTop: "15px",
    [" & fieldset"]: {
      paddingLeft: "10px",
      borderRadius: "4px",
    },
  },
  inputLabelProps: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    paddingTop: "2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  button: {
    fontSize: "14px",
  },
  submitBtn: {
    borderRadius: "8px",
    fontSize: "14px",
    transition: "0.3s",
    padding: "6px 18px",
    marginTop: "5px",
    "&:hover": {
      transition: "0.3s",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));

export const predictionChartStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "10px",
    marginTop: "20px",
    marginBottom: "40px",
  },
  tooltipContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
  },
  tooltip: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    marginTop: "10px",
  },
  tooltipText: {
    fontSize: "14px",
  },
  legendLabel: {
    fontSize: "13px",
    marginLeft: "5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  legendBox: {
    width: "30px",
    height: "15px",
    padding: "4px",
    borderRadius: "3px",
    transition: "0.3s",
    "&:hover": {
      cursor: "pointer",
      transition: "0.3s",
    },
  },
  mi: {
    backgroundColor: "#50c38b",
    border: `1px solid #4bab7c`,
  },
  sad: {
    backgroundColor: "#666666",
    border: `1px solid #545454`,
  },
  anxiety: {
    backgroundColor: "#4a86e8",
    border: `1px solid #3f72c4`,
  },
  suicidal: {
    backgroundColor: "#c77dff",
    border: `1px solid #9f6bc7`,
  },
  anger: {
    backgroundColor: "#c7434a",
    border: `1px solid #943237`,
  },
  psycho: {
    backgroundColor: "#3d7364",
    border: `1px solid #3d6e60`,
  },
  agreeContainer: {
    marginTop: "30px",
  },
  agreeText: {
    fontSize: "16px",
    marginTop: "15px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  formControlLabel: {
    marginRight: "15px",
  },
  radio: {
    height: "1px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  radioOneRoot: {
    "& svg": {
      width: "22px",
      height: "22px",
    },
    "&$checked": {
      color: "green",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  radioOneChecked: {},
  radioLabel: {
    fontSize: "15px",
  },
  textFieldContainer: {
    marginBottom: "15px",
  },
  inputField: {
    width: "100%",
    marginTop: "15px",
    [" & fieldset"]: {
      paddingLeft: "10px",
      borderRadius: "4px",
    },
  },
  inputLabelProps: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    paddingTop: "2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  button: {
    fontSize: "14px",
  },
  submitBtn: {
    borderRadius: "8px",
    fontSize: "14px",
    transition: "0.3s",
    padding: "6px 18px",
    marginTop: "5px",
    "&:hover": {
      transition: "0.3s",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));

export const progressStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px",
    },
  },
  secTitle: {
    color: theme.palette.DARK_GREY,
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      fontSize: "15px",
      marginBottom: "5px",
    },
  },
  divider: {
    marginBottom: "15px",
  },
  progressContainer: {
    marginLeft: "-12px",
    marginBottom: "20px",
  },
  badge: {
    marginLeft: "12px",
    marginTop: "5px",
  },
  checkIcon: {
    padding: "0px",
    color: "green",
    height: "18px",
  },
  label: {
    textAlign: "center",
  },
  badgeBtn: {
    minWidth: "23px",
    padding: "4px",
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "6px",
    transition: "0.3s",
    "&:hover": {
      cursor: "pointer",
      border: `1px solid #888`,
      transition: "0.3s",
      backgroundColor: "#f2f2f2",
    },
  },
  badgeBtnSelected: {
    backgroundColor: "#e0e0e0",
    // backgroundColor: "#95d5b2",
  },
}));

export const nextPostStyles = makeStyles((theme) => ({
  btnContainer: {
    marginTop: "8px",
    display: "flex",
    justifyContent: "space-between",
  },
  prevBtn: {
    fontSize: "14px",
    padding: "5px 12px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  nextBtn: {
    boxShadow: "0px 0px",
    fontSize: "14px",
    padding: "8px 12px",
    "&:hover": {
      boxShadow: "0px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));
