import React, { useState } from "react";
import { nextPostStyles } from "./styles";
import { Button } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BACKEND_URL } from "../../../config";
import axios from "axios";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";
import FeedbackMsg from "../../helpers/FeedbackMsg";
import * as actions from "../../../store/actions/actionTypes";

function NextPost(props) {
  const styleClasses = nextPostStyles();

  const { submitted, setIsError, setSubmitted, setAnalysisCompleted } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { questionNo } = useParams();
  const handleAuthFail = useHandleAuthFail();

  // for feedback msg
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("");

  // complete modal
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { userAuthData } = useSelector((state) => state.auth);
  const { answers } = useSelector((state) => state.analysis);
  const { summaryAgreed } = useSelector((state) => state.analysisInput);
  const { summaryComment } = useSelector((state) => state.analysisInput);
  const { chartAgreed } = useSelector((state) => state.analysisInput);
  const { chartComment } = useSelector((state) => state.analysisInput);

  // for feedback msg
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handlePreviousClick = () => {
    if (questionNo != 0) {
      history.push(`/pilot-study/post-analysis/${parseInt(questionNo) - 1}`);
    }
  };

  const handleNextClick = () => {
    if (questionNo != answers?.length) {
      history.push(`/pilot-study/post-analysis/${parseInt(questionNo) + 1}`);
    }
  };

  const goToFeedback = () => {
    history.push(`/pilot-study/feedback`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    const newAnswers = [...answers];
    newAnswers[questionNo - 1].summaryAgreed = summaryAgreed;
    newAnswers[questionNo - 1].summaryComment = summaryComment;
    newAnswers[questionNo - 1].chartAgreed = chartAgreed;
    newAnswers[questionNo - 1].chartComment = chartComment;
    dispatch({
      type: actions.SET_ANALYSIS_ANSWERS,
      payload: newAnswers,
    });

    setIsError(false);
    setModalOpen(false);
    setSeverity("");
    try {
      const response = await axios.put(
        `${BACKEND_URL}/post-analysis/submit/${userAuthData.id}`,
        {
          answers,
        }
      );
      if (response.data.success) {
        setIsSubmitted(false);
        setMsg("Your answers have been submitted.");
        setOpen(true);
        setSubmitted(true);
        setAnalysisCompleted(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleAuthFail();
        return;
      }
      setIsSubmitted(false);
      if (error.response && error.response.status === 500) {
        setIsError(true);
      } else {
        setMsg("Please answer all the questions.");
        setOpen(true);
        setSeverity("error");
      }
    }
  };

  return (
    <div>
      <FeedbackMsg
        open={open}
        handleClose={handleClose}
        msg={msg}
        severity={severity}
        duration={12000}
      ></FeedbackMsg>

      <div className={styleClasses.btnContainer}>
        <Button
          className={styleClasses.prevBtn}
          onClick={handlePreviousClick}
          disabled={questionNo == 1}
        >
          <NavigateBeforeIcon />
          Previous Post
        </Button>
        {questionNo == answers?.length ? (
          <div>
            {submitted ? (
              <Button
                color="primary"
                variant="contained"
                className={styleClasses.nextBtn}
                onClick={goToFeedback}
                disabled={isSubmitted}
              >
                Go To Feedback <NavigateNextIcon />
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                className={styleClasses.nextBtn}
                onClick={handleModalOpen}
                disabled={isSubmitted}
              >
                Submit answers & Go To Post-Questionnaire <NavigateNextIcon />
              </Button>
            )}
          </div>
        ) : (
          <Button
            className={styleClasses.nextBtn}
            onClick={handleNextClick}
            disabled={questionNo == answers?.length}
          >
            Next Post <NavigateNextIcon />
          </Button>
        )}
      </div>

      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to submit the post-analysis?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once you submit the post-analysis and proceed to post-questionnaire,
            you cannot change your answers for the post-analysis. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NextPost;
