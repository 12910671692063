import { makeStyles } from "@material-ui/core/styles";

export const indexStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "50px",
    paddingBottom: "50px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "30px",
      paddingBottom: "30px",
    },
  },
  container: {
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "5px",
    padding: "30px 60px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 40px",
    },
  },
  section: {
    marginBottom: "40px",
  },
  sectionTitle: {
    fontSize: "18px",
    fontFamily: "Roboto-Medium",
    marginBottom: "10px",
  },
  divider: {
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "6px",
    },
  },
  teamContainer: {
    marginBottom: "10px",
  },
  teamTitle: {
    fontSize: "15px",
    fontFamily: "Roboto-Medium",
    marginBottom: "5px",
  },
  listItem: {
    paddingLeft: "0px",
  },
  listDot: {
    height: "14px",
  },
  listText: {
    fontSize: "14px",
    marginLeft: "-22px",
  },
  textContainer: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
  emailContainer: {
    fontSize: "14px",
  },
  emailIcon: {
    fontSize: "20px",
    marginRight: "8px",
  },
  contact: {
    color: "#5e5ee6",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  instructionsTitle: {
    fontSize: "15px",
    marginTop: "30px",
    fontFamily: "Roboto-Bold",
  },
  instructionsText: {
    marginTop: "10px",
    fontSize: "14px",
  },
  categoryTitle: {
    fontSize: "14px",
    fontFamily: "Roboto-Bold",
    marginTop: "10px",
  },
  categoryText: {
    fontSize: "14px",
    fontFamily: "Roboto-regular",
  },
}));
