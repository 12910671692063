// user data
export const SET_USER_AUTH_DATA = "SET_USER_AUTH_DATA";
export const SET_USER_DATA = "SET_USER_DATA";

// pre-questionnaire
export const SET_PRE_QUESTIONS = "SET_PRE_QUESTIONS";

// post-questionnaire
export const SET_FEEDBACK_QUESTIONS = "SET_FEEDBACK_QUESTIONS";

// Post analysis
export const SET_ANALYSIS_QUESTION = "SET_ANALYSIS_QUESTION";
export const SET_ANALYSIS_ANSWERS = "SET_ANALYSIS_ANSWERS";

// post analysis user input
export const SET_SUMMARY_AGREED = "SET_SUMMARY_AGREED";
export const SET_SUMMARY_COMMENT = "SET_SUMMARY_COMMENT";
export const SET_CHART_AGREED = "SET_CHART_AGREED";
export const SET_CHART_COMMENT = "SET_CHART_COMMENT";
