import React, { useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { Stepper, Step, StepLabel, StepContent } from "@material-ui/core";
import { aboutStudyStyles } from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import { Divider } from "@material-ui/core";

function AboutStudy() {
  const styleClasses = aboutStudyStyles();
  const history = useHistory();
  const location = useLocation();

  // Stepper
  const [activeStep, setActiveStep] = useState(
    location.state?.activePage ? location.state?.activePage : 0
  );
  const steps = ["About Study", "Instrcutions for the participants"];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const goToPreQuestionnaire = () => {
    history.push("/pilot-study/pre-questionnaire");
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <div>
              <Typography className={styleClasses.stepDescription}>
                Mental health issues remain a leading cause for poor well-being
                globally. In 2018, the World Health Organization (WHO) estimated
                that over 400 million people around the world would be affected
                by mental disorders like depression and anxiety, and these
                numbers are growing exponentially as a result of the COVID-19
                pandemic - primarily due to lockdowns, social isolation etc.
                <br />
                <br />
                People increasingly use digital media such as social media
                groups and online forums to express and exchange their
                psychological distress and to seek relief. InnerAwareness is a
                tool designed to analyze users" language (e.g. social media
                posts, statuses) expressed in digital media and to predict their
                likelihood of developing a mental illness or maladaptive mental
                condition, using computer-based Artificial Intelligence (AI)
                technology. The tool also predicts emotions (e.g. sadness,
                anxiety), mental states (e.g. suicidal thoughts), and
                psychosomatic issues. The ultimate goal of this tool is to
                provide a tool (either web-based or app-based system) for
                individuals to assess and develop self-awareness of their mental
                wellbeing, across time.
                <br />
                <br />
                This project is a collaboration between the University of
                Adelaide, Australia and the University of Colombo, Sri Lanka. It
                is funded by the Australian Academy of Science and the
                Australian Department of Industry, Science, Energy and Resources
                under its “Regional Collaborations Programme COVID-19 Digital
                Grants”.
              </Typography>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <div>
              <Typography className={styleClasses.stepDescription}>
                Please read the instructions carefully. If you have any
                questions about this project or the research study, please
                contact Dr Thushari Atapattu. (
                <span className={styleClasses.contact}>
                  thushari.atapattu[At]adelaide[Dot]edu[Dot]au
                </span>
                )
                <br />
                <br />
                This research study has three parts - pre-questionnaire, study,
                and post-questionnaire. The completion of all three parts is
                mandatory to receive a payment of LKR 2500.
              </Typography>

              <div>
                <Typography
                  variant="h1"
                  className={styleClasses.instructionsTitle}
                >
                  Part 1 - Pre-questionnaire (Estimated time - maximum 15
                  minutes)
                </Typography>
                <Typography className={styleClasses.instructionsText}>
                  In this section, you will be asked to complete a survey about
                  your background such as your qualifications, age, (any) mental
                  health concerns (10 questions in total). Completing all the
                  questions is mandatory. For text answers, please provide as
                  much information as possible.
                </Typography>
              </div>

              <div>
                <Typography
                  variant="h1"
                  className={styleClasses.instructionsTitle}
                >
                  Part 2 - Research study (Estimated time - maximum 2 hours)
                </Typography>

                <Typography className={styleClasses.instructionsText}>
                  In this section, you will be asked to evaluate a tool/system
                  developed by the InnerAwareness project team. You will have to
                  read 100 posts collected from some mental health related
                  public Facebook groups in Sri Lanka and India. Your tasks are
                  as follows.
                </Typography>

                <ol className={styleClasses.olist}>
                  <li className={styleClasses.olistItem}>
                    Read the entire Facebook post provided to you
                  </li>
                  <li>
                    Imagine you as a mental health professional. If someone
                    (e.g. a potential patient) explains their problem to you, as
                    it is expressed in the given post, how would you make
                    clinical judgments?
                    <ul className={styleClasses.ulist}>
                      <li className={styleClasses.ulistItem}>
                        Is this related to a mental illness? (Please see below
                        for the definition of mental illness as defined in our
                        research.)
                      </li>
                      <li className={styleClasses.ulistItem}>
                        Please judge whether the given post has a higher
                        probability to be considered as a "mental illness", and
                        answer whether you agree if so, or not. Please provide
                        us some feedback on how you came to your decision.
                      </li>
                      <li className={styleClasses.ulistItem}>
                        Next, you will be provided with a bar chart where our
                        tool has categorized the given post according to some
                        emotions (e.g. sadness), mental states (e.g. suicidal
                        thoughts), or psychosomatic issues
                      </li>
                      <li className={styleClasses.ulistItem}>
                        Please judge whether the probabilities of each of these
                        categories is reasonable to the given post and rate your
                        judgment in the Likert scale. For example, if a given
                        post is more about the emotion "sadness" but not about
                        "suicidal", you will see our tool has predicted higher
                        probabilities for sadness and lower probabilities to
                        "suicidal". Please note that our tool may have performed
                        wrong predictions - it"s your responsibility to identify
                        these errors and evaluate the tool in a way human users
                        may assess it. This way we can improve our
                        InnerAwareness tool in future studies. Please provide
                        some feedback on how you came across you decision. You
                        are prompted to learn more about how our research
                        defined each category by clicking "info/?" link
                      </li>
                    </ul>
                  </li>
                </ol>

                <Typography className={styleClasses.instructionsText}>
                  Each post includes help seeking from the Facebook group by the
                  reporting individual (e.g. an issue related to me) or of a
                  third party (e.g. my partner, children, parent) for a mental
                  illness or a related problem that may lead to a maladaptive
                  mental condition or mental illness. The post should not
                  include advice by mental health professionals (e.g. not
                  seeking help but provide advice), matters related to Facebook
                  group (e.g. rules of the Facebook group), thanking to others
                  who helped, seeking recommendations (e.g. Psychology courses,
                  good books).
                </Typography>

                <Typography className={styleClasses.catDefinitionTitle}>
                  Definitions of each category
                </Typography>
                <Divider className={styleClasses.divider} />

                <div>
                  <Typography className={styleClasses.categoryTitle}>
                    Mental illness
                  </Typography>
                  <Typography className={styleClasses.categoryText}>
                    The post includes a diagnosis of a mental illness (e.g.
                    depression), getting treatments for a mental illness, or
                    self-identification of a mental illness based on history and
                    seek help.
                  </Typography>
                </div>

                <div>
                  <Typography className={styleClasses.categoryTitle}>
                    Sadness
                  </Typography>
                  <Typography className={styleClasses.categoryText}>
                    The post includes sadness, unhappy or sorry that may lead to
                    a maladaptive mental condition or mental illness.
                  </Typography>
                </div>

                <div>
                  <Typography className={styleClasses.categoryTitle}>
                    Anger
                  </Typography>
                  <Typography className={styleClasses.categoryText}>
                    The post includes anger that may lead to a maladaptive
                    mental condition or mental illness.
                  </Typography>
                </div>

                <div>
                  <Typography className={styleClasses.categoryTitle}>
                    Anxiety/stress
                  </Typography>
                  <Typography className={styleClasses.categoryText}>
                    The post includes stress, fear, worry about something that
                    may lead to a maladaptive mental condition or mental illnes.
                  </Typography>
                </div>

                <div>
                  <Typography className={styleClasses.categoryTitle}>
                    Suicidal thoughts
                  </Typography>
                  <Typography className={styleClasses.categoryText}>
                    The post includes suicidal thoughts or no interest in life.
                  </Typography>
                </div>
                <div>
                  <Typography className={styleClasses.categoryTitle}>
                    Psychosomatic
                  </Typography>
                  <Typography className={styleClasses.categoryText}>
                    The post includes psychosomatic issues (e.g. insomnia,
                    fatigue, headaches, upset stomach) that associated with
                    underlying mental distress or may lead to a maladaptive
                    mental condition or mental illness.
                  </Typography>
                </div>
              </div>

              <div>
                <Typography
                  variant="h1"
                  className={styleClasses.instructionsTitle}
                >
                  Part 3 - Post-questionnaire (Estimated time - 15 minutes)
                </Typography>
                <Typography className={styleClasses.instructionsText}>
                  In this section, you will be asked to complete a survey about
                  your feedback about the research study and tool (10
                  questions). Completing all the questions is mandatory. Please
                  provide as much information as possible for text answers.
                </Typography>
              </div>
            </div>
          </div>
        );
      default:
        return "Not Found";
    }
  };

  return (
    <>
      <div>
        <Stepper
          className={styleClasses.stepper}
          activeStep={activeStep}
          orientation="vertical"
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                onClick={handleStep(index)}
                className={styleClasses.stepLabel}
              >
                {label}
              </StepLabel>

              <StepContent>
                {getStepContent(index)}
                <div className={styleClasses.stepperBtnContainer}>
                  <Button
                    variant="contained"
                    onClick={
                      activeStep === steps?.length - 1
                        ? goToPreQuestionnaire
                        : handleNext
                    }
                    className={`${styleClasses.button} ${styleClasses.nextBtn}`}
                  >
                    {"Next"}
                  </Button>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    </>
  );
}

export default AboutStudy;
