import React, { useEffect, useState } from "react";
import { logOutUser } from "../store/actions/authActions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

function useHandleAuthFail() {
  const dispatch = useDispatch();
  const history = useHistory();

  return () => {
    logOutUser()(dispatch);
    history.push("/signin");
  };
}

export default useHandleAuthFail;
