import { makeStyles } from "@material-ui/core/styles";

export const feedbackStyles = makeStyles((theme) => ({
  loadingRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    marginTop: "-50px",
  },
  container: {
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "10px",
    padding: "30px 60px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 30px",
    },
  },
  categoryContainer: {
    padding: "10px 20px",
    marginBottom: "10px",
  },
  divider: {
    marginTop: "5px",
  },
  appIcon:{
    transform: "rotate(45deg)",
    fontSize: "10px",
    marginRight: "8px",
    color: `${theme.palette.BLACK}`
  },
  categoryTitle: {
    fontSize: "15px",
    fontFamily: "Roboto-Medium",
  },
  categoryText: {
    fontSize: "15px",
    marginTop: "10px",
  },
  title: {
    fontSize: "15px",
    fontFamily: "Roboto-Medium",
    marginBottom: "6px",
  },
  greyText: {
    color: theme.palette.DARK_GREY,
    fontSize: "14px",
  },
  questionNo: {
    marginBottom: "6px",
    fontSize: "17px",
    fontFamily: "Roboto-Medium",
  },
  formContainer: {
    marginTop: "10px",
    marginLeft: "20px",
  },
  radioLabel: {
    fontSize: "14px",
  },
  radioIcon: {
    height: "12px",
  },
  btnContainer: {
    marginTop: "8px",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    textTransform: "none",
    fontSize: "14px",
  },
  saveButton: {
    fontSize: "12px",
    marginTop: "5px",
  },
  saveIcon: {
    height: "16px",
  },
  saveText: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  submitBtn: {
    boxShadow: "0px 0px",
    fontSize: "14px",
    padding: "8px 12px",
    "&:hover": {
      boxShadow: "0px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));
