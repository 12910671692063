import { makeStyles } from "@material-ui/core/styles";

export const desktopNavBarStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  navBarContainer: {
    backgroundColor: theme.palette.DARK_BLUE,
    boxShadow: "0px 0px",
  },
  title: {
    flexGrow: 1,
    marginLeft: "15px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  signInBtn: {
    color: `${theme.palette.DARK_YELLOW}`,
    border: `2px solid ${theme.palette.DARK_YELLOW}`,
    borderRadius: "50px",
    transition: "0.5s",
    padding: "3px 18px",
    "&:hover": {
      color: theme.palette.DARK_BLUE,
      backgroundColor: theme.palette.DARK_YELLOW,
      transition: "0.5s",
    },
  },
  logoWrapper: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  brainLogo: {
    height: "30px",
  },
  menuItem: {
    marginRight: "40px",
    "&:hover": {
      color: theme.palette.DARK_YELLOW,
      cursor: "pointer",
    },
  },
  activeItem: {
    color: theme.palette.DARK_YELLOW,
  },
  navbarProfile: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      "& $username": {
        color: theme.palette.DARK_YELLOW,
      },
    },
  },
  maleIcon: {
    height: "30px",
    // marginRight: "10px",
  },
  username: {
    color: theme.palette.WHITE,
  },
  popover: {
    marginTop: "5px",
  },
  popoverMenuPaper: {
    width: "200px",
  },
  popoverMenuList: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  popoverMenuItem: {
    paddingTop: "12px",
    paddingBottom: "12px",
    color: theme.palette.primary.dark,
    fontSize: "15px",
  },
  popoverMenuIcon: {
    color: theme.palette.primary.dark,
    marginRight: "10px",
    fontSize: "20px",
  },
  signOutIcon: {
    transform: "scaleX(-1)",
  },
  loadingContainer: {
    height: "30px",
  },
  loadingIcon: {
    color: "#fff",
  },
}));

export const mobileNavBarStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: "20px 0px",
    backgroundColor: theme.palette.DARK_BLUE,
  },
  menuButton: {
    color: theme.palette.WHITE,
    padding: "0px",
  },
  title: {
    color: theme.palette.WHITE,
    fontSize: "18px",
    marginLeft: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  logoWrapper: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  brainLogo: {
    height: "30px",
    marginBottom: "-2px",
  },
  signIn: {
    marginRight: "40px",
    color: theme.palette.WHITE,
    "&:hover": {
      color: theme.palette.DARK_YELLOW,
      cursor: "pointer",
    },
  },
  signInBtn: {
    color: `${theme.palette.DARK_YELLOW}`,
    border: `2px solid ${theme.palette.DARK_YELLOW}`,
    borderRadius: "50px",
    transition: "0.5s",
    padding: "3px 18px",
    "&:hover": {
      color: theme.palette.DARK_BLUE,
      backgroundColor: theme.palette.DARK_YELLOW,
      transition: "0.5s",
    },
  },
  listContainer: {
    width: 250,
    height: "100vh",
  },
  list: {
    height: "100%",
    backgroundColor: theme.palette.DARK_BLUE,
  },
  listIcon: {
    color: theme.palette.WHITE,
  },
  listText: {
    color: theme.palette.WHITE,
  },
  listItemHighlight: {
    color: theme.palette.DARK_YELLOW,
  },
  signOutIcon: {
    transform: "scaleX(-1)",
  },
}));
