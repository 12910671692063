import React, { useState, useEffect } from "react";
import { Switch } from "react-router-dom";
import { Container, MenuItem, MenuList, Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import PrivateRoute from "../../auth/PrivateRoute";
import PilotStudyDescription from "./AboutStudy";
import PreQuestionnaire from "./PreQuestionnaire";
import PostAnalysis from "./PostAnalysis";
import Feedback from "./Feedback";
import { indexStyles } from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useMediaQuery } from "react-responsive";
import Completion from "./Completion";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useSelector } from "react-redux";
import { BACKEND_URL } from "../../config";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import useHandleAuthFail from "../../utils/useHandleAuthFail";

function PilotStudyBase() {
  const styleClasses = indexStyles();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const { userAuthData } = useSelector((state) => state.auth);
  const handleAuthFail = useHandleAuthFail();

  const [isLoading, setIsLoading] = useState(true);
  const [activeItem, setActiveItem] = useState("");
  const [tab, setTab] = useState(0);

  const [preQCompleted, setPreQCompleted] = useState(null);
  const [analysisCompleted, setAnalysisCompleted] = useState(null);
  const [feedbackCompleted, setFeedbackCompleted] = useState(null);

  const [isError, setIsError] = useState(false);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    retrieveCompleteStatuses();
  }, []);

  const retrieveCompleteStatuses = async () => {
    if (userAuthData.id) {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/pilotStudy/completion/${userAuthData.id}`
        );

        if (response.data.success) {
          setPreQCompleted(response.data.preQ);
          setAnalysisCompleted(response.data.analysis);
          setFeedbackCompleted(response.data.feedback);
        }
        setIsLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleAuthFail();
          return;
        }
        setIsLoading(false);
        setIsError(true);
      }
    }
  };

  const goToPilotStudyDescription = () => {
    history.push("/pilot-study/description");
  };
  const goToPreQuestionnaire = () => {
    history.push("/pilot-study/pre-questionnaire");
  };
  const goToPostAnalysis = () => {
    history.push(`/pilot-study/post-analysis/${1}`);
  };
  const goToFeedback = () => {
    history.push("/pilot-study/feedback");
  };
  const goToCompletion = () => {
    history.push("/pilot-study/complete");
  };

  const displayPreQSuccess = () => {
    if (isLoading) {
      return (
        <div className={styleClasses.loadingContainer}>
          <CircularProgress
            size={"16px"}
            className={styleClasses.loadingIcon}
          />
        </div>
      );
    } else if (preQCompleted) {
      return <CheckCircleIcon className={styleClasses.checkIcon} />;
    } else {
      return <PriorityHighIcon className={styleClasses.remainingIcon} />;
    }
  };

  const displayAnalysisSuccess = () => {
    if (isLoading) {
      return (
        <div className={styleClasses.loadingContainer}>
          <CircularProgress
            size={"16px"}
            className={styleClasses.loadingIcon}
          />
        </div>
      );
    } else if (analysisCompleted) {
      return <CheckCircleIcon className={styleClasses.checkIcon} />;
    } else {
      return <PriorityHighIcon className={styleClasses.remainingIcon} />;
    }
  };

  const displayFeedbackSuccess = () => {
    if (isLoading) {
      return (
        <div className={styleClasses.loadingContainer}>
          <CircularProgress
            size={"16px"}
            className={styleClasses.loadingIcon}
          />
        </div>
      );
    } else if (feedbackCompleted) {
      return <CheckCircleIcon className={styleClasses.checkIcon} />;
    } else {
      return <PriorityHighIcon className={styleClasses.remainingIcon} />;
    }
  };

  return (
    <>
      <div className={styleClasses.root}>
        <Container maxWidth={false}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              lg={1}
              className={styleClasses.marginGridItem}
            ></Grid>
            <Grid item xs={12} md={3} lg={2}>
              <div className={styleClasses.container}>
                {!isMobile && (
                  <MenuList className={styleClasses.menuList}>
                    <MenuItem
                      className={styleClasses.menuItemFirst}
                      onClick={goToPilotStudyDescription}
                      selected={activeItem === "description"}
                    >
                      <Typography className={styleClasses.menuItemText}>
                        About Study
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      className={styleClasses.menuItem}
                      onClick={goToPreQuestionnaire}
                      selected={activeItem === "pre-questionnaire"}
                    >
                      <Typography
                        className={styleClasses.menuItemText}
                        noWrap={false}
                      >
                        Pre-Questionnaire
                      </Typography>
                      {displayPreQSuccess()}
                    </MenuItem>

                    <MenuItem
                      className={styleClasses.menuItem}
                      onClick={goToPostAnalysis}
                      selected={activeItem === "post-analysis"}
                    >
                      <Typography className={styleClasses.menuItemText}>
                        Post Analysis
                      </Typography>
                      {displayAnalysisSuccess()}
                    </MenuItem>
                    <MenuItem
                      className={styleClasses.menuItem}
                      onClick={goToFeedback}
                      selected={activeItem === "feedback"}
                    >
                      <Typography className={styleClasses.menuItemText}>
                        Post-questionnaire
                      </Typography>
                      {displayFeedbackSuccess()}
                    </MenuItem>
                    <MenuItem
                      className={styleClasses.menuItemLast}
                      onClick={goToCompletion}
                      selected={activeItem === "completion"}
                    >
                      <Typography className={styleClasses.menuItemText}>
                        Completion
                      </Typography>
                    </MenuItem>
                  </MenuList>
                )}

                {isMobile && (
                  <Tabs
                    value={tab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab
                      onClick={goToPilotStudyDescription}
                      label={
                        <Typography className={styleClasses.menuItemText}>
                          About Study
                        </Typography>
                      }
                    />
                    <Tab
                      onClick={goToPreQuestionnaire}
                      label={
                        <Typography
                          className={styleClasses.menuItemText}
                          noWrap={false}
                        >
                          Pre-Questionnaire
                        </Typography>
                      }
                    />
                    <Tab
                      onClick={goToPostAnalysis}
                      label={
                        <Typography className={styleClasses.menuItemText}>
                          Post Analysis
                        </Typography>
                      }
                    />
                    <Tab
                      onClick={goToFeedback}
                      label={
                        <Typography className={styleClasses.menuItemText}>
                          Post-questionnaire
                        </Typography>
                      }
                    />
                    <Tab
                      onClick={goToCompletion}
                      label={
                        <Typography className={styleClasses.menuItemText}>
                          Completion
                        </Typography>
                      }
                    />
                  </Tabs>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={9} lg={8}>
              <Switch>
                <PrivateRoute
                  exact
                  path="/pilot-study/description"
                  component={PilotStudyDescription}
                />
                <PrivateRoute
                  exact
                  path="/pilot-study/pre-questionnaire"
                  component={PreQuestionnaire}
                  setPreQCompleted={setPreQCompleted}
                />
                <PrivateRoute
                  exact
                  path="/pilot-study/post-analysis/:questionNo"
                  component={PostAnalysis}
                  setAnalysisCompleted={setAnalysisCompleted}
                />
                <PrivateRoute
                  exact
                  path="/pilot-study/feedback"
                  component={Feedback}
                  setFeedbackCompleted={setFeedbackCompleted}
                />
                <PrivateRoute
                  exact
                  path="/pilot-study/complete"
                  component={Completion}
                />
              </Switch>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={1}
              className={styleClasses.marginGridItem}
            ></Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default PilotStudyBase;
