import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

export const DarkBlueTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.DARK_BLUE,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.DARK_BLUE,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.DARK_BLUE,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.DARK_BLUE,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.DARK_BLUE,
      },
    },
  },
}))(TextField);

export const GreyTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.MID_GREY,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.MID_GREY,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.MID_GREY,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.MID_GREY,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.MID_GREY,
      },
    },
  },
}))(TextField);

