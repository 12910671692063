import { makeStyles } from "@material-ui/core/styles";

export const indexStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.DARK_BLUE,
    paddingTop: "50px",
    paddingBottom: "50px",
    height: "80vh",
  },
  paper: {
    width: "40%",
    borderRadius: "20px",
    padding: "50px 30px",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  formContainer: {
    width: "80%",
    margin: "auto",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  border: {
    border: `2px solid ${theme.palette.MID_GREY}`,
    borderRadius: "50px",
  },
  inputField: {
    width: "100%",
    fontSize: "13px",
    marginBottom: "20px",
    [" & fieldset"]: {
      borderRadius: "8px",
      paddingLeft: "10px",
      fontSize: "13px",
    },
  },
  inputLabelProps: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    paddingTop: "2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  dateContainer: {
    marginBottom: "20px",
  },
  radioContainer: {
    marginBottom: "20px",
  },
  formLabel: {
    fontSize: "13px",
    textDecoration: "none",
  },
  formControlLabel: {
    fontSize: "12px",
  },
  radio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  radioLabel: {
    fontSize: "14px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  signupBtn: {
    color: theme.palette.WHITE,
    backgroundColor: theme.palette.DARK_BLUE,
    borderRadius: "8px",
    fontSize: "14px",
    transition: "0.3s",
    padding: "6px 18px",
    marginTop: "5px",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.DARK_BLUE_HOVER,
      transition: "0.3s",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  alreadyReg: {
    fontSize: "14px",
    marginTop: "8px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  signinText: {
    color: theme.palette.LIGHT_BLUE,
    "&:hover": {
      cursor: "pointer",
    },
  },
  feedbackContainer: {
    backgroundColor: "#fcfff5",
    color: "#1d561e",
    border: "1px solid #1d561e",
    borderRadius: "8px",
    marginBottom: "10px",
    padding: "5px 10px",
  },
  feedbackTitle: {
    fontSize: "14px",
    fontFamily: "Roboto-Bold",
    display: "flex",
    alignItems: "center",
  },
  feedbackIcon: {
    fontSize: "16px",
    marginRight: "5px",
  },
  feedbackMsg: {
    fontSize: "14px",
  },
  feedbackContainerError: {
    backgroundColor: "#fff6f6",
    color: "#973937",
    border: "1px solid #973937",
    borderRadius: "8px",
    marginBottom: "10px",
    padding: "5px 10px",
  },
  feedbackTitleError: {
    fontSize: "14px",
    fontFamily: "Roboto-Medium",
    display: "flex",
    alignItems: "center",
  },
  feedbackIconError: {
    fontSize: "16px",
    marginRight: "5px",
  },
}));

export const confirmationStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.DARK_BLUE,
    paddingBottom: "50px",
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: theme.palette.WHITE,
    textAlign: "center",
    fontSize: "18px",
  },
  button: {
    color: `${theme.palette.DARK_YELLOW}`,
    border: `2px solid ${theme.palette.DARK_YELLOW}`,
    borderRadius: "50px",
    transition: "0.5s",
    padding: "3px 20px",
    marginTop: "15px",
    "&:hover": {
      color: theme.palette.DARK_BLUE,
      backgroundColor: theme.palette.DARK_YELLOW,
      transition: "0.5s",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3px 15px",
      fontSize: "12px",
    },
  },
}));
