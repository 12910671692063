import { combineReducers } from "redux";
import authReducer from "./authReducer";
import preQReducer from "./preQReducer";
import feedbackQReducer from "./feedbackReducer";
import postAnalysisQReducer from "./postAnalysisReducer";
import analysisInputReducer from "./analysisInputReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  preQ: preQReducer,
  feedbackQ: feedbackQReducer,
  analysis: postAnalysisQReducer,
  analysisInput: analysisInputReducer
});

export default rootReducer;
