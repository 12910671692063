import React, { useEffect, useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { feedbackStyles } from "./styles";
import { BACKEND_URL } from "../../../config";
import axios from "axios";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import MultipleChoiceSingle from "../Questions/MultipleChoiceSingle";
import MultipleChoiceMulti from "../Questions//MultipleChoiceMulti";
import ParagraphQuestion from "../Questions//ParagraphQuestion";
import FeedbackMsg from "../../helpers/FeedbackMsg";
import Error from "../Error/Error";
import NotComplete from "../Error/NotComplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";
import * as actions from "../../../store/actions/actionTypes";
import { useDispatch } from "react-redux";
import { Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AppsIcon from "@material-ui/icons/Apps";

function Feedback({ setFeedbackCompleted }) {
  const styleClasses = feedbackStyles();
  const { userAuthData } = useSelector((state) => state.auth);
  const history = useHistory();
  const handleAuthFail = useHandleAuthFail();
  const dispatch = useDispatch();
  const { feedbackAnswers: answers } = useSelector((state) => state.feedbackQ);

  // for feedback msg
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("");

  // complete modal
  const [modalOpen, setModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [submitted, setSubmitted] = useState(null);
  const [isError, setIsError] = useState(false);
  const [analysisCompleted, setAnalysisCompleted] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    retrievePostQuestions();
  }, []);

  // for feedback msg
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // complete modal
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const retrievePostQuestions = async () => {
    setIsError(false);
    if (userAuthData.id) {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/feedback/${userAuthData.id}`
        );

        if (response.data.success) {
          setQuestions(response.data.questions);
          dispatch({
            type: actions.SET_FEEDBACK_QUESTIONS,
            payload: response.data.answers,
          });
          setErrors(new Array(response.data.questions.length).fill(""));
          setIsLoading(false);
          setAnalysisCompleted(true);
          setSubmitted(response.data.submitted);
        } else {
          setAnalysisCompleted(false);
          setIsLoading(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleAuthFail();
          return;
        }
        setIsLoading(false);
        setIsError(true);
      }
    }
  };

  const handleSaveProgress = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsError(false);
    setSeverity("");
    try {
      const response = await axios.put(
        `${BACKEND_URL}/feedback/save/${userAuthData.id}`,
        {
          answers,
        }
      );
      if (response.data.success) {
        setIsSubmitted(false);
        setMsg("Your answers have been saved.");
        setOpen(true);
        setFeedbackCompleted(response.data.completed);
        setErrors(new Array(questions.length).fill(""));
      }
    } catch (error) {
      setIsSubmitted(false);
      if (error.response && error.response.status === 401) {
        handleAuthFail();
        return;
      }
      if (error.response && error.response.status === 500) {
        setIsError(true);
      } else {
        if (error.response.data.errors) {
          setErrors(error.response.data.errors);
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsError(false);
    setModalOpen(false);
    setSeverity("");
    try {
      const response = await axios.put(
        `${BACKEND_URL}/feedback/submit/${userAuthData.id}`,
        {
          answers,
        }
      );
      if (response.data.success) {
        setIsSubmitted(false);
        setMsg("Your answers have been saved.");
        setOpen(true);
        setFeedbackCompleted(response.data.completed);
        setErrors(new Array(questions.length).fill(""));
        setSubmitted(true);
      }
    } catch (error) {
      setIsSubmitted(false);
      if (error.response && error.response.status === 401) {
        handleAuthFail();
        return;
      }
      if (error.response && error.response.status === 500) {
        setIsError(true);
      } else {
        if (error.response.data.errors) {
          setErrors(error.response.data.errors);
        }
      }
      setMsg(
        "Answers could not be submitted. Please check all your answers again."
      );
      setOpen(true);
      setSeverity("error");
    }
  };

  const goToCompletion = () => {
    history.push("/pilot-study/complete");
  };

  const displayQuestions = () => {
    if (isLoading) {
      return (
        <div className={styleClasses.loadingRoot}>
          <CircularProgress></CircularProgress>
        </div>
      );
    } else if (isError) {
      return <Error />;
    } else if (analysisCompleted === false) {
      return (
        <div className={styleClasses.container}>
          <NotComplete
            msg={
              "Please submit the post-analysis section before moving onto the post-questionnaire."
            }
          />
        </div>
      );
    } else if (questions?.length > 0 && answers?.length > 0) {
      return (
        <>
          <div className={styleClasses.container}>
            <Typography className={styleClasses.title}>Feedback</Typography>
            <div>
              <Typography className={styleClasses.greyText}>
                It is mandatory to complete all the questions ( Estimated time -
                maximum 15 minutes )
              </Typography>
            </div>
          </div>

          <form>
            {questions.map((q, index, arr) => {
              return (
                <div key={q["_id"]["$oid"]}>
                  {q.category === "userInterface" && (
                    <div className={styleClasses.categoryContainer}>
                      <Typography className={styleClasses.categoryTitle}>
                        <AppsIcon className={styleClasses.appIcon} />
                        Research study - Website
                      </Typography>
                      <Divider className={styleClasses.divider} />
                    </div>
                  )}
                  {q.category === "usefulness" && (
                    <div className={styleClasses.categoryContainer}>
                      <Typography className={styleClasses.categoryTitle}>
                        <AppsIcon className={styleClasses.appIcon} />
                        Usefulness of the tool (i.e. web-based system)
                      </Typography>
                      <Divider className={styleClasses.divider} />

                      <Typography className={styleClasses.categoryText}>
                        The ultimate goal of this project is to provide a tool
                        (either web-based or app-based system) for individuals
                        to assess their emotions and mental health/conditions
                        through evaluating their language (e.g. written text
                        and/or spoken language) using a computer-based software
                        (e.g. Artificial Intelligence) and develop
                        self-awareness of their mental wellbeing, across time.
                        With this goal in mind, please answer the below
                        questions 3 to 6:
                      </Typography>
                    </div>
                  )}
                  {q.category === "AIPredictions" && (
                    <div className={styleClasses.categoryContainer}>
                      <Typography className={styleClasses.categoryTitle}>
                        <AppsIcon className={styleClasses.appIcon} />
                        Tool predictions using "Artificial Intelligence (AI)"
                      </Typography>
                      <Divider className={styleClasses.divider} />
                    </div>
                  )}

                  <div
                    className={
                      index + 1 === arr.length
                        ? `${styleClasses.container} ${styleClasses.lastContainer}`
                        : `${styleClasses.container}`
                    }
                  >
                    {q.type === "multipleChoice-single" && (
                      <MultipleChoiceSingle
                        questionNo={index + 1}
                        question={q}
                        answers={answers}
                        answerIndex={index}
                        errors={errors}
                        setErrors={setErrors}
                        submitted={submitted}
                        isSubmitted={isSubmitted}
                        handleSaveProgress={handleSaveProgress}
                        ACTION_TYPE={actions.SET_FEEDBACK_QUESTIONS}
                      ></MultipleChoiceSingle>
                    )}
                    {q.type === "multipleChoice-multi" && (
                      <MultipleChoiceMulti
                        questionNo={index + 1}
                        question={q}
                        answers={answers}
                        answerIndex={index}
                        errors={errors}
                        setErrors={setErrors}
                        submitted={submitted}
                        isSubmitted={isSubmitted}
                        handleSaveProgress={handleSaveProgress}
                        ACTION_TYPE={actions.SET_FEEDBACK_QUESTIONS}
                      ></MultipleChoiceMulti>
                    )}
                    {q.type === "paragraph" && (
                      <ParagraphQuestion
                        questionNo={index + 1}
                        question={q}
                        answers={answers}
                        answerIndex={index}
                        errors={errors}
                        setErrors={setErrors}
                        submitted={submitted}
                        isSubmitted={isSubmitted}
                        handleSaveProgress={handleSaveProgress}
                        ACTION_TYPE={actions.SET_FEEDBACK_QUESTIONS}
                      ></ParagraphQuestion>
                    )}
                  </div>
                </div>
              );
            })}
            {!submitted && (
              <div className={styleClasses.btnContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  className={`${styleClasses.button} ${styleClasses.submitBtn}`}
                  disabled={isSubmitted}
                  onClick={handleModalOpen}
                >
                  Submit answers & finish the questionnaire
                </Button>
              </div>
            )}

            {submitted && (
              <div className={styleClasses.btnContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  className={`${styleClasses.button} ${styleClasses.submitBtn}`}
                  disabled={isSubmitted}
                  onClick={goToCompletion}
                >
                  Complete the study
                </Button>
              </div>
            )}
            <Dialog
              open={modalOpen}
              onClose={handleModalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Do you want to submit the post-questionnaire?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Once you submit the post-questionnaire and proceed , you
                  cannot change your answers for the post-questionnaire. Are you
                  sure?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                  Cancel
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </>
      );
    }
  };

  return (
    <div className={styleClasses.root}>
      <FeedbackMsg
        open={open}
        handleClose={handleClose}
        msg={msg}
        severity={severity}
        duration={12000}
      ></FeedbackMsg>
      {displayQuestions()}
    </div>
  );
}

export default Feedback;
