import React, { useState } from "react";
import { FormControl, FormGroup } from "@material-ui/core";
import { FormControlLabel, FormHelperText } from "@material-ui/core";
import { Typography, Button } from "@material-ui/core";
import { multipleChoiceStyles } from "./styles";
import { formatNumber } from "../../../utils/helpers";
import { BlueCheckBox } from "./../../helpers/CheckBox";
import { GreyTextField } from "../../helpers/CustomTextFields";
import SaveIcon from "@material-ui/icons/Save";
import { useDispatch } from "react-redux";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

function MultipleChoiceMulti(props) {
  const { answers, questionNo, question, answerIndex } = props;
  const { errors, setErrors, submitted, ACTION_TYPE } = props;
  const { isSubmitted, handleSaveProgress } = props;
  const styleClasses = multipleChoiceStyles();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;

    const newAnswers = [...answers];
    const indexOfAnswer = question.answers.findIndex((i) => i === value);
    if (checked === true) {
      newAnswers[answerIndex]["answers"][indexOfAnswer]["selected"] = true;
      newAnswers[answerIndex]["answers"][indexOfAnswer]["value"] = value;
    } else if (checked === false) {
      newAnswers[answerIndex]["answers"][indexOfAnswer]["selected"] = false;
      newAnswers[answerIndex]["answers"][indexOfAnswer]["value"] = "";
      newAnswers[answerIndex]["answers"][indexOfAnswer]["specify"] = "";
    }

    dispatch({
      type: ACTION_TYPE,
      payload: newAnswers,
    });
    const newErrors = [...errors];
    newErrors[answerIndex] = "";
    setErrors(newErrors);
  };

  const handleTextFieldChange = (event, index) => {
    const value = event.target.value;

    const newAnswers = [...answers];
    newAnswers[answerIndex]["answers"][index]["specify"] = value;
    dispatch({
      type: ACTION_TYPE,
      payload: newAnswers,
    });

    const newErrors = [...errors];
    newErrors[answerIndex] = "";
    setErrors(newErrors);
  };

  const disableInput = () => {
    if ("requiredQuestion" in question) {
      if (
        answers[question.requiredQuestion - 1]["answers"][0]["selected"] ===
        true
      ) {
        return false || isSubmitted || submitted;
      }
      return true || isSubmitted || submitted;
    }
    return isSubmitted || submitted;
  };

  const displayChecked = (index) => {
    if ("requiredQuestion" in question) {
      if (!answers[question.requiredQuestion - 1]["answers"][0].selected) {
        return false;
      }
    }
    return answers[answerIndex]["answers"][index]["selected"];
  };

  const displayTextFieldValue = (index) => {
    if ("requiredQuestion" in question) {
      if (!answers[question.requiredQuestion - 1]["answers"][0].selected) {
        return "";
      }
    }
    return answers[answerIndex]["answers"][index]["specify"];
  };

  return (
    <>
      <Typography className={styleClasses.title}>
        Question {formatNumber(questionNo)}
      </Typography>

      <div className={styleClasses.content}>
        <Typography className={styleClasses.question}>
          {question.question}
        </Typography>

        <div>
          <FormControl
            component="fieldset"
            className={styleClasses.formControl}
            error={errors[questionNo - 1]?.length > 0}
          >
            <FormGroup onChange={handleChange}>
              {question.answers.map((answer, index) => (
                <div key={question["_id"]["$oid"] + index}>
                  <FormControlLabel
                    className={styleClasses.controlLabel}
                    value={answer}
                    control={
                      <BlueCheckBox
                        className={styleClasses.checkBox}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        name={answer}
                        checked={displayChecked(index)}
                        disabled={disableInput()}
                      />
                    }
                    label={
                      <Typography className={styleClasses.checkBoxLabel}>
                        {answer}
                      </Typography>
                    }
                  />
                  {answer?.includes("Please specify") &&
                    answers[answerIndex]["answers"][index]["selected"] && (
                      <GreyTextField
                        id="answer"
                        multiline
                        className={styleClasses.inputField}
                        InputLabelProps={{
                          className: styleClasses.inputLabelProps,
                        }}
                        value={displayTextFieldValue(index)}
                        variant="outlined"
                        size="small"
                        onChange={(e) => handleTextFieldChange(e, index)}
                        disabled={disableInput()}
                      />
                    )}
                </div>
              ))}

              <div className={styleClasses.btnContainer}>
                <Button
                  edge="start"
                  className={`${styleClasses.button} ${styleClasses.saveButton}`}
                  color="inherit"
                  variant="contained"
                  disabled={disableInput()}
                  onClick={handleSaveProgress}
                >
                  <SaveIcon className={styleClasses.saveIcon} />
                  <Typography className={styleClasses.saveText}>
                    Save Current Progress
                  </Typography>
                </Button>
              </div>
            </FormGroup>
            {errors[questionNo - 1] && (
              <FormHelperText>{errors[questionNo - 1]}</FormHelperText>
            )}
          </FormControl>
        </div>
      </div>
    </>
  );
}

export default MultipleChoiceMulti;
