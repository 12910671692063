import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import brain from "../images/brain.svg";
import { DarkBlueTextField } from "../helpers/CustomTextFields";
import { indexStyles } from "./styles";
import { useHistory } from "react-router-dom";

function Home() {
  const styleClasses = indexStyles();
  const history = useHistory();

  const goToPilotStudyDescription = () => {
    history.push("/pilot-study/description");
  };
  const goToAboutUs = () => {
    history.push("/about-us");
  };

  return (
    <div className={styleClasses.wrapper}>
      <div className={styleClasses.container}>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10} className={styleClasses.flexContainer}>
            <div className={styleClasses.textContainer}>
              <Typography className={styleClasses.mainText}>
                Mental Health and
                <br />
                <span className={styleClasses.analysis}>Emotion Analysis</span>
              </Typography>
              <Typography className={styleClasses.subText}>
                Mental health issues remain a leading cause for poor well-being
                globally. In 2018, the World Health Organization (WHO) estimated
                that over 400 million people around the world would be affected
                by mental disorders like depression and anxiety, and these
                numbers are growing exponentially as a result of the COVID-19
                pandemic - primarily due to lockdowns, social isolation etc.
                <br />
                <br />
                People increasingly use digital media such as social media
                groups and online forums to express and exchange their
                psychological distress and to seek relief. InnerAwareness is a
                tool designed to analyze users’ language (e.g. social media
                posts, statuses) expressed in digital media and to predict their
                likelihood of developing a mental illness or maladaptive mental
                condition, using computer-based Artificial Intelligence (AI)
                technology. The tool also predicts emotions (e.g. sadness,
                anxiety), mental states (e.g. suicidal thoughts), and
                psychosomatic issues. The ultimate goal of this tool is to
                provide a tool (either web-based or app-based system) for
                individuals to assess and develop self-awareness of their mental
                wellbeing, across time.
              </Typography>
              <div className={styleClasses.btnContainer}>
                <Button
                  className={`${styleClasses.button} ${styleClasses.agreeBtn}`}
                  onClick={goToPilotStudyDescription}
                >
                  Agree to participate
                </Button>
                <Button
                  className={`${styleClasses.button} ${styleClasses.readMoreBtn}`}
                  onClick={goToAboutUs}
                >
                  Read more
                </Button>
              </div>
            </div>

            <div className={styleClasses.imgContainer}>
              <img src={brain} className={styleClasses.brain} alt="image" />
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Home;
