import { makeStyles } from "@material-ui/core/styles";

export const indexStyles = makeStyles((theme) => ({
  loadingRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    marginTop: "-50px",
  },
  container: {
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "10px",
    padding: "30px 30px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 30px",
    },
  },
  flex: {
    minHeight: "60vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "-20px",
  },
  text: {
    textAlign: "center",
  },
}));
