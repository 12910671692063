import { makeStyles } from "@material-ui/core/styles";

export const paragraphStyles = makeStyles((theme) => ({
  title: {
    fontSize: "15px",
    fontFamily: "Roboto-Medium",
    marginBottom: "6px",
  },
  question: {
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  inputField: {
    width: "100%",
    marginTop: "15px",
    [" & fieldset"]: {
      paddingLeft: "10px",
      borderRadius: "4px",
    },
  },
  inputLabelProps: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    paddingTop: "2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  btnContainer: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    fontSize: "14px",
  },
  saveButton: {
    fontSize: "12px",
  },
  saveIcon: {
    height: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "15px",
    },
  },
  saveText: {
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
}));

export const multipleChoiceStyles = makeStyles((theme) => ({
  title: {
    fontSize: "15px",
    fontFamily: "Roboto-Medium",
    marginBottom: "6px",
  },
  question: {
    fontSize: "15px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  formControl: {
    width: "100%",
  },
  controlLabel: {
    marginBottom: "10px",
  },
  radio: {
    height: "5px",
    fontSize: "1px",
  },
  radioLabel: {
    fontSize: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  checkBox: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  checkBoxLabel: {
    fontSize: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  inputField: {
    width: "100%",
    marginBottom: "15px",
    [" & fieldset"]: {
      paddingLeft: "10px",
      borderRadius: "4px",
    },
  },
  inputLabelProps: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    paddingTop: "2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  btnContainer: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    fontSize: "14px",
  },
  saveButton: {
    fontSize: "12px",
  },
  saveIcon: {
    height: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "15px",
    },
  },
  saveText: {
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
}));
