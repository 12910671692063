import React from "react";
import { notCompleteStyles } from "./styles";
import { Typography } from "@material-ui/core";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";

function NotComplete({ msg }) {
  const styleClasses = notCompleteStyles();

  return (
    <>
      <div className={styleClasses.root}>
        <PriorityHighIcon className={styleClasses.icon} />
        <Typography>
          {msg}
        </Typography>
      </div>
    </>
  );
}

export default NotComplete;
