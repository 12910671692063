import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import MainNavBar from "./components/Layout/NavBar";
import Footer from "./components/Layout/Footer";
import theme from "./Theme";
import { ThemeProvider } from "@material-ui/core/styles";
import Signin from "./components/Signin";
import Profile from "./components/Profile";
import PilotStudyBase from "./components/PilotStudy";
import Confirmation from "./components/Signup/Confirmation";
import NotFound from "./components/NotFound";
import { useDispatch } from "react-redux";
import * as actions from "./store/actions/actionTypes";
import PrivateRoute from "./auth/PrivateRoute";
import AuthRoute from "./auth/AuthRoute";
import jwt_decode from "jwt-decode";
import { logOutUser } from "./store/actions/authActions";
import AboutUs from "./components/AboutUs";
import setAuthToken from "./utils/setAuthToken";

function App() {
  const dispatch = useDispatch();

  if (localStorage.mindSpaceToken) {
    const token = localStorage.getItem("mindSpaceToken");
    const userData = JSON.parse(localStorage.getItem("mindSpaceUser"));

    setAuthToken(token);
    const decoded = jwt_decode(token);
    dispatch({
      type: actions.SET_USER_AUTH_DATA,
      payload: decoded,
    });
    dispatch({
      type: actions.SET_USER_DATA,
      payload: userData,
    });
    
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      logOutUser()(dispatch);
      window.location.href = "./signin";
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <div id="page-container">
            <div id="content-wrap">
              <MainNavBar />
              <Switch>
                <Route exact path="/" component={Home} />
                <AuthRoute exact path="/signin" component={Signin} />
                <PrivateRoute path="/profile/info" component={Profile} />
                <PrivateRoute path="/profile/change-password" component={Profile} />
                <AuthRoute path="/confirmation/:otp/:userId" component={Confirmation} />
                <PrivateRoute path="/pilot-study" component={PilotStudyBase} />
                <Route path="/about-us" component={AboutUs} />
                <Route component={NotFound} />
              </Switch>
            </div>
            <footer id="footer">
              <Footer />
            </footer>
          </div>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
