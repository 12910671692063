import React, { useState, useEffect } from "react";
import { Typography, Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { confirmationStyles } from "./styles";
import { useHistory } from "react-router-dom";
import Error from "../Lotties/error.json";
import Success from "../Lotties/success.json";
import Loading from "../Lotties/loading.json";
import Lottie from "react-lottie";
import axios from "axios";
import { BACKEND_URL } from "../../config";

function Confirmation() {
  const styleClasses = confirmationStyles();
  const history = useHistory();

  const { otp, userId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    handleVerification();
  }, []);

  const handleVerification = async () => {
    if (otp && userId) {
      try {
        const verified = await axios.post(`${BACKEND_URL}/users/confirmation`, {
          otp,
          userId,
        });
        if (verified.data.success) {
          setIsVerified(true);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setIsVerified(false);
        setError(error.response.data.error);
      }
    }
  };

  const goToSignin = () => {
    history.push("/signin");
  };

  const goToHome = () => {
    history.push("/");
  };

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: Success,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: Error,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const displayConfirmation = () => {
    if (isLoading) {
      return (
        <div>
          <Lottie options={loadingOptions} height={150} width={150} />
        </div>
      );
    } else {
      if (isVerified) {
        return (
          <>
            <div>
              <Lottie options={successOptions} height={200} width={200} />
            </div>
            <Typography className={styleClasses.text}>
              Your account has been verified!
              <br /> You can now sign in with your new account.
            </Typography>
            <Button className={styleClasses.button} onClick={goToSignin}>
              Sign in
            </Button>
          </>
        );
      } else {
        return (
          <>
            <div>
              <Lottie options={errorOptions} height={150} width={200} />
            </div>
            <Typography className={styleClasses.text}>
              Verification Failed!
              <br /> {error}
            </Typography>
            <Button className={styleClasses.button} onClick={goToHome}>
              Home Page
            </Button>
          </>
        );
      }
    }
  };

  return <div className={styleClasses.root}>{displayConfirmation()}</div>;
}

export default Confirmation;
