import * as actions from "../actions/actionTypes";
import isEmpty from "is-empty";

const initialState = {
  feedbackAnswers: [],
};

function feedbackQReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_FEEDBACK_QUESTIONS:
      return {
        ...state,
        feedbackAnswers: payload,
      };
    default:
      return state;
  }
}

export default feedbackQReducer;
