import React, { useState, useEffect } from "react";
import { Drawer, List, Grid } from "@material-ui/core";
import { ListItemIcon, ListItemText, ListItem } from "@material-ui/core";
import { Typography, IconButton } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import MenuIcon from "@material-ui/icons/Menu";
import brainLogo from "../../images/brainLogo.svg";
import InputIcon from "@material-ui/icons/Input";
import theme from "../../../Theme";
import SubjectIcon from "@material-ui/icons/Subject";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { mobileNavBarStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser } from "../../../store/actions/authActions";
import PersonIcon from "@material-ui/icons/Person";

function MobileNavBar() {
  const styleClasses = mobileNavBarStyles();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("");

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setActiveItem("home");
        break;
      case "/about-us":
        setActiveItem("aboutUs");
        break;
      case "/profile/info":
        setActiveItem("profile");
        break;
      case "/profile/change-password":
        setActiveItem("profile");
        break;
      case "/pilot-study/description":
        setActiveItem("researchStudy");
        break;
      default:
        setActiveItem("");
    }
  }, [location.pathname]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const goToHome = () => {
    setActiveItem("home");
    history.push("/");
  };

  const goToProfile = () => {
    setActiveItem("profile");
    history.push("/profile/info");
  };

  const goToResearchStudy = () => {
    setActiveItem("researchStudy");
    history.push("/pilot-study/description");
  };

  const goToSignUp = () => {
    setActiveItem("signUp");
    history.push("/signUp");
  };

  const goToSignIn = () => {
    setActiveItem("signIn");
    history.push("/signIn");
  };

  const goToAboutUs = () => {
    setActiveItem("aboutUs");
    history.push("/about-us");
  };

  const logOut = () => {
    setActiveItem("");
    logOutUser()(dispatch);
    history.push("/");
  };

  return (
    <div>
      <Grid container className={styleClasses.gridContainer}>
        <Grid item xs={1}></Grid>
        <Grid
          item
          container
          xs={9}
          sm={10}
          alignItems="center"
          justifyContent="space-between"
        >
          <div style={{ display: "flex", flexGrow: 1 }}>
            <div className={styleClasses.logoWrapper}>
              <img
                src={brainLogo}
                className={styleClasses.brainLogo}
                alt="image"
              />
            </div>
            <Typography
              onClick={(e) => {
                setActiveItem("home");
                goToHome();
              }}
              className={styleClasses.title}
            >
              InnerAwareness
            </Typography>
          </div>
        </Grid>

        <Grid item container xs={2} sm={1} justifyContent="center">
          <IconButton
            edge="start"
            className={styleClasses.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <div
          className={styleClasses.listContainer}
          role="presentation"
          onClick={() => {
            setDrawerOpen(false);
          }}
          onKeyDown={() => {
            setDrawerOpen(false);
          }}
        >
          <List className={styleClasses.list}>
            <ListItem button onClick={goToHome}>
              <ListItemIcon>
                <HomeIcon
                  className={styleClasses.listIcon}
                  style={{
                    color: activeItem === "home" && theme.palette.DARK_YELLOW,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Home"
                className={styleClasses.listText}
                style={{
                  color: activeItem === "home" && theme.palette.DARK_YELLOW,
                }}
              />
            </ListItem>

            {isAuthenticated && (
              <ListItem button onClick={goToProfile}>
                <ListItemIcon>
                  <PersonIcon
                    className={styleClasses.listIcon}
                    style={{
                      color:
                        activeItem === "profile" && theme.palette.DARK_YELLOW,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Profile"
                  className={styleClasses.listText}
                  style={{
                    color:
                      activeItem === "profile" && theme.palette.DARK_YELLOW,
                  }}
                />
              </ListItem>
            )}

            <ListItem button onClick={goToResearchStudy}>
              <ListItemIcon>
                <SubjectIcon
                  className={styleClasses.listIcon}
                  style={{
                    color:
                      activeItem === "researchStudy" &&
                      theme.palette.DARK_YELLOW,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Research Study"
                className={styleClasses.listText}
                style={{
                  color:
                    activeItem === "researchStudy" && theme.palette.DARK_YELLOW,
                }}
              />
            </ListItem>

            <ListItem button onClick={goToAboutUs}>
              <ListItemIcon>
                <InfoIcon
                  className={styleClasses.listIcon}
                  style={{
                    color:
                      activeItem === "aboutUs" && theme.palette.DARK_YELLOW,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="About Us"
                className={styleClasses.listText}
                style={{
                  color: activeItem === "aboutUs" && theme.palette.DARK_YELLOW,
                }}
              />
            </ListItem>

            {!isAuthenticated && (
              <ListItem button onClick={goToSignIn}>
                <ListItemIcon>
                  <InputIcon
                    className={styleClasses.listIcon}
                    style={{
                      color:
                        activeItem === "signIn" && theme.palette.DARK_YELLOW,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="SignIn"
                  className={styleClasses.listText}
                  style={{
                    color: activeItem === "signIn" && theme.palette.DARK_YELLOW,
                  }}
                />
              </ListItem>
            )}

            {isAuthenticated && (
              <ListItem button onClick={logOut}>
                <ListItemIcon>
                  <ExitToAppIcon
                    className={`${styleClasses.listIcon} ${styleClasses.signOutIcon}`}
                    style={{
                      color:
                        activeItem === "signOut" && theme.palette.DARK_YELLOW,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Sign out"
                  className={styleClasses.listText}
                  style={{
                    color:
                      activeItem === "signOut" && theme.palette.DARK_YELLOW,
                  }}
                />
              </ListItem>
            )}
          </List>
        </div>
      </Drawer>
    </div>
  );
}

export default MobileNavBar;
