import { makeStyles } from "@material-ui/core/styles";

export const indexStyles = makeStyles((theme) => ({
  root: {
    marginTop: "60px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
  container:{
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "6px",
  },
  list: {
    padding: "0px 0px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  listIcon: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  listText: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
}));

export const editUserInfoStyles = makeStyles((theme) => ({
  root: {
    padding: "30px 30px 30px 30px",
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "6px",
  },
  infoTop: {
    width: "100%",
    display: "flex",
    paddingBottom: "20px",
  },
  profileIcon: {
    width: "100px",
    [theme.breakpoints.down("sm")]: {
      width: "120px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70px",
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "20px",
  },
  email: {
    color: theme.palette.DARK_GREY,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  editProfile: {
    color: `${theme.palette.BLACK}`,
    border: `1px solid ${theme.palette.MID_GREY}`,
    backgroundColor: theme.palette.primary.MID_GREY,
    borderRadius: "8px",
    fontSize: "14px",
    transition: "0.5s",
    padding: "3px 12px",
    marginTop: "10px",
    "&:hover": {
      color: theme.palette.BLACK,
      backgroundColor: theme.palette.MID_GREY_HOVER,
      border: `1px solid ${theme.palette.MID_GREY}`,
      transition: "0.5s",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3px 10px",
    },
  },
  editIcon: {
    fontSize: "14px",
    marginRight: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  editText: {
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  section2: {
    marginTop: "20px",
    marginBottom: "15px",
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "6px",
  },
  finalContainer: {
    display: "flex",
    alignItems: "center",
  },
  textFieldContainer: {
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  infoTitle: {
    fontSize: "15px",
    marginRight: "5px",
    minWidth: "20%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "4px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  infoTitleEditing: {
    minWidth: "20%",
    [theme.breakpoints.down("xs")]: {
      marginRight: "15px",
    },
  },
  infoData: {
    fontSize: "15px",
    color: theme.palette.DARK_GREY,
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  inputField: {
    width: "100%",
    marginBottom: "10px",
    [" & fieldset"]: {
      paddingLeft: "10px",
      borderRadius: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "6px",
    },
  },
  inputLabelProps: {
    color: theme.palette.DARK_GREY,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    paddingTop: "2px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  formControlLabel: {
    fontSize: "12px",
  },
  radio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  radioLabel:{
    [theme.breakpoints.down("md")]: {
      fontSize: "14px"
    },
  },
  btnContainer: {
    marginTop: "15px",
    marginBottom: "25px",
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelBtn: {
    borderRadius: "8px",
    fontSize: "13px",
    transition: "0.3s",
    padding: "5px 15px",
    marginRight: "5px",
    "&:hover": {
      transition: "0.3s",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  saveBtn: {
    color: `${theme.palette.WHITE}`,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: "8px",
    fontSize: "13px",
    transition: "0.3s",
    padding: "5px 15px",
    "&:hover": {
      backgroundColor: theme.palette.DARK_BLUE_HOVER,
      transition: "0.3s",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));

export const changePasswordStyles = makeStyles((theme) => ({
  root: {
    padding: "30px 30px 30px 30px",
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "6px",
  },
  inputField: {
    width: "100%",
    marginBottom: "15px",
    [" & fieldset"]: {
      paddingLeft: "10px",
      borderRadius: "10px",
    },
  },
  inputLabelProps: {
    color: theme.palette.DARK_GREY,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    paddingTop: "2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  btnContainer: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    textTransform: "none",
    fontSize: "16px",
    boxShadow: "0px 1px 2px rgba(9, 9, 9, 0.1)",
  },
  clrBtn: {
    borderRadius: "8px",
    fontSize: "13px",
    transition: "0.3s",
    padding: "5px 15px",
    marginRight: "5px",
    "&:hover": {
      transition: "0.3s",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  chngBtn: {
    color: `${theme.palette.WHITE}`,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: "8px",
    fontSize: "13px",
    transition: "0.3s",
    padding: "5px 15px",
    "&:hover": {
      backgroundColor: theme.palette.PRIMARY_BLUE_HOVER,
      transition: "0.3s",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));
