import React from "react";
import { progressStyles } from "./styles";
import Badge from "@material-ui/core/Badge";
import { Typography, Divider, Box } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Progress() {
  const { questionNo } = useParams();
  const history = useHistory();
  const styleClasses = progressStyles();
  const { answers } = useSelector((state) => state.analysis);

  const handleQuestionClick = (e, number) => {
    if (number != questionNo) {
      history.push(`/pilot-study/post-analysis/${number}`);
    }
  };

  const displayBadgeContent = (i, index) => {
    if (answers[index]["relevant"]) {
      if (
        i.summaryAgreed !== "" &&
        i.chartAgreed !== "" &&
        i.summaryComment.trim().length !== 0 &&
        i.chartComment.trim().length !== 0
      ) {
        return <CheckCircleIcon className={styleClasses.checkIcon} />;
      }
    } else {
      if (i.summaryAgreed !== "" && i.summaryComment.trim().length !== 0) {
        return <CheckCircleIcon className={styleClasses.checkIcon} />;
      }
    }
  };

  return (
    <div className={styleClasses.root}>
      <Typography className={styleClasses.secTitle}>
        Your current progress
      </Typography>
      <Divider className={styleClasses.divider} />

      <div className={styleClasses.progressContainer}>
        {answers.map((i, index) => (
          <Badge
            key={i.questionId}
            onClick={(e) => {
              handleQuestionClick(e, index + 1);
            }}
            className={styleClasses.badge}
            badgeContent={displayBadgeContent(i, index)}
          >
            <Box
              className={
                questionNo == index + 1
                  ? `${styleClasses.badgeBtn} ${styleClasses.badgeBtnSelected}`
                  : `${styleClasses.badgeBtn}`
              }
            >
              <Typography className={styleClasses.label}>
                {index + 1}
              </Typography>
            </Box>
          </Badge>
        ))}
      </div>
    </div>
  );
}
