import * as actions from "./actionTypes";
import setAuthToken from "../../utils/setAuthToken";

// Log user out
export const logOutUser = () => (dispatch) => {
  localStorage.removeItem("mindSpaceToken");
  localStorage.removeItem("mindSpaceUser");
  setAuthToken(false);
  dispatch({
    type: actions.SET_USER_AUTH_DATA,
    payload: {},
  });
  dispatch({
    type: actions.SET_USER_DATA,
    payload: {},
  });
};
