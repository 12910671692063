import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#191d5e",
    },
    test: {
      main: "green",
    },
    WHITE: "#ffffff",
    DARK_WHITE: "#F9F9F9",
    BLACK: "#424547",
    PRIMARY_BLUE_HOVER: "#3949ad",
    LIGHT_BLUE: "#85A6E5",
    DARK_BLUE: "#0C0F38",
    DARK_BLUE_HOVER: "#141852",
    LIGHT_GREY: "#F5F8FE",
    MID_GREY: "#C9C9C9",
    MID_GREY_HOVER: "#d6d6d6",
    DARK_GREY: "#8d9296",
    DARK_YELLOW: "#FDD430",
    LIGHT_PURPLE: "#3f51b5",
    DARK_PURPLE: "#420B74",
  },
  overrides: {
    MuiButton: {
      root:{
        textTransform: "none",
        fontSize: "16px",
      }
    },
  },
});

export default theme;
