import * as actions from "../actions/actionTypes";

const initialState = {
  answers: [],
  question: {},
};

function postAnalysisQReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_ANALYSIS_QUESTION:
      return {
        ...state,
        question: payload,
      };
    case actions.SET_ANALYSIS_ANSWERS:
      return {
        ...state,
        answers: payload,
      };
    default:
      return state;
  }
}

export default postAnalysisQReducer;
