import React, { useState } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText } from "@material-ui/core";
import { Typography, Button } from "@material-ui/core";
import { percentageStyles } from "./styles";
import { GreyTextField } from "../../helpers/CustomTextFields";
import { DarkRedRadio, GreenRadio } from "../../helpers/RadioButton";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { useSelector, useDispatch } from "react-redux";
import FeedbackMsg from "../../helpers/FeedbackMsg";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";
import * as actions from "../../../store/actions/actionTypes";

function Percentage(props) {
  const { submitted } = props;
  const { setIsError, setAnalysisCompleted } = props;

  const { questionNo } = useParams();
  const dispatch = useDispatch();

  const { userAuthData } = useSelector((state) => state.auth);
  const { answers } = useSelector((state) => state.analysis);
  const { question } = useSelector((state) => state.analysis);

  const { summaryAgreed } = useSelector((state) => state.analysisInput);
  const { summaryComment } = useSelector((state) => state.analysisInput);
  const { chartAgreed } = useSelector((state) => state.analysisInput);
  const { chartComment } = useSelector((state) => state.analysisInput);

  const handleAuthFail = useHandleAuthFail();

  const styleClasses = percentageStyles();

  // for feedback msg
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const [agreeError, setAgreeError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // for feedback msg
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const handleChange = (event) => {
    dispatch({
      type: actions.SET_SUMMARY_AGREED,
      payload: event.target.value,
    });
    setAgreeError(false);
  };

  const handleComment = (event) => {
    dispatch({
      type: actions.SET_SUMMARY_COMMENT,
      payload: event.target.value,
    });
    setCommentError(false);
  };

  const handleSummarySubmit = async (e) => {
    e.preventDefault();
    const newAnswers = [...answers];
    newAnswers[questionNo - 1].summaryAgreed = summaryAgreed;
    newAnswers[questionNo - 1].summaryComment = summaryComment.trim();
    newAnswers[questionNo - 1].chartAgreed = chartAgreed;
    newAnswers[questionNo - 1].chartComment = chartComment.trim();
    dispatch({
      type: actions.SET_ANALYSIS_ANSWERS,
      payload: newAnswers,
    });

    if (userAuthData.id && !agreeError && !commentError) {
      setIsError(false);
      setIsSubmitted(true);
      try {
        const response = await axios.put(
          `${BACKEND_URL}/post-analysis/summary/${userAuthData.id}/${questionNo}`,
          {
            summaryAgreed,
            summaryComment,
          }
        );
        if (response.data.success) {
          setIsSubmitted(false);
          setMsg("Your answers have been saved.");
          setOpen(true);
          setAnalysisCompleted(response.data.completed);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleAuthFail();
          return;
        }
        setIsSubmitted(false);
        if (error.response && error.response.status === 500) {
          setIsError(true);
        } else {
          if (error.response?.data.error === "agree") {
            setAgreeError(true);
          } else if (error.response?.data.error === "comment") {
            setCommentError(true);
          }
        }
      }
    }
  };

  const displayAgreeText = () => {
    if (!answers[parseInt(questionNo) - 1].relevant) {
      if (summaryAgreed === "agree") {
        return (
          <Typography className={styleClasses.irrelevantTextContainer}>
            InnerAwareness tool predicts this post as not related to a mental
            condition. Therefore, tool does not analyse emotions further.
          </Typography>
        );
      } else if (summaryAgreed === "disagree") {
        return (
          <Typography className={styleClasses.irrelevantTextContainer}>
            InnerAwareness tool predicts this post as not related to a mental
            condition. Therefore, tool does not analyse emotions further. If you
            think this post relates to a mental condition, please comment
            suitable emotions and other conditions for this post (e.g. mental
            illness such as depression/anxiety, sadness, anxiety/stress,
            suicidal thoughts, anger, psychosomatic or any other condition).
          </Typography>
        );
      }
    }
  };

  const saveButtonColor = () => {
    if (!answers[parseInt(questionNo) - 1].relevant) {
      return "primary";
    }
  };

  const saveButtonText = () => {
    if (!answers[parseInt(questionNo) - 1].relevant) {
      return "Submit Response";
    } else {
      return "Save Response";
    }
  };

  return (
    <>
      <FeedbackMsg
        open={open}
        handleClose={handleClose}
        msg={msg}
      ></FeedbackMsg>

      <div className={styleClasses.root}>
        <Typography className={styleClasses.mainText}>
          This post is <span>{question.predicted_0}%</span> likely relate to a
          mental condition.
        </Typography>

        <form onSubmit={handleSummarySubmit}>
          <div className={styleClasses.agreeContainer}>
            <Typography className={styleClasses.ratingText}>
              Do you agree?
            </Typography>

            <FormControl
              component="fieldset"
              className={styleClasses.formControl}
              error={agreeError}
            >
              {agreeError && (
                <FormHelperText>Please select an answer.</FormHelperText>
              )}
              <RadioGroup
                row
                name="agree"
                value={summaryAgreed}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={"agree"}
                  control={
                    <GreenRadio
                      className={styleClasses.radio}
                      disabled={submitted}
                    />
                  }
                  label={
                    <Typography className={styleClasses.radioLabel}>
                      Agree
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={"disagree"}
                  control={
                    <DarkRedRadio
                      className={styleClasses.radio}
                      disabled={submitted}
                    />
                  }
                  label={
                    <Typography className={styleClasses.radioLabel}>
                      Disagree
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className={styleClasses.irrelevantTextContainer}>
            {displayAgreeText()}
          </div>

          <div className={styleClasses.textFieldContainer}>
            <GreyTextField
              id="comment"
              label="Please provide your feedback."
              multiline
              className={styleClasses.inputField}
              InputLabelProps={{
                className: styleClasses.inputLabelProps,
              }}
              value={summaryComment}
              variant="outlined"
              size="small"
              rows={3}
              onChange={handleComment}
              error={commentError}
              helperText={
                commentError &&
                "Feedback cannot be empty. Please provide your feedback."
              }
              disabled={submitted}
              required
            />
            <div className={styleClasses.btnContainer}>
              <Button
                type="submit"
                variant="contained"
                color={saveButtonColor()}
                className={`${styleClasses.button} ${styleClasses.submitBtn}`}
                disabled={isSubmitted || submitted}
              >
                {saveButtonText()}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Percentage;
