import * as actions from "../actions/actionTypes";

const initialState = {
  summaryAgreed: "",
  summaryComment: "",
  chartAgreed: "",
  chartComment: "",
};

function analysisInputReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_SUMMARY_AGREED:
      return {
        ...state,
        summaryAgreed: payload,
      };
    case actions.SET_SUMMARY_COMMENT:
      return {
        ...state,
        summaryComment: payload,
      };
    case actions.SET_CHART_AGREED:
      return {
        ...state,
        chartAgreed: payload,
      };
    case actions.SET_CHART_COMMENT:
      return {
        ...state,
        chartComment: payload,
      };
    default:
      return state;
  }
}

export default analysisInputReducer;
