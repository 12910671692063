import { makeStyles } from "@material-ui/core/styles";

export const indexStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.DARK_BLUE,
    paddingTop: "50px",
    paddingBottom: "50px",
    height: "80vh",
  },
  paper: {
    width: "50%",
    borderRadius: "20px",
    padding: "100px 30px",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  welcomeTextContainer: {
    marginBottom: "30px",
  },
  welcomeText: {
    fontSize: "22px",
    fontFamily: "Roboto-Medium",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  welcomeSubText: {
    fontSize: "16px",
    color: theme.palette.DARK_GREY,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  formContainer: {
    width: "80%",
    margin: "auto",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  signinLogo: {
    width: "18px",
    marginRight: "8px",
    [theme.breakpoints.down("xs")]: {
      width: "15px",
    },
  },
  textContainer: {
    color: theme.palette.MID_GREY,
    marginTop: "30px",
    marginBottom: "30px",
  },
  text: {
    fontSize: "14px",
    width: "100%",
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.MID_GREY}`,
    lineHeight: "0.1em",
    margin: "10px 0 20px",
  },
  textSpan: {
    background: "#fff",
    padding: "0 10px",
  },
  inputField: {
    width: "100%",
    marginBottom: "15px",
    [" & fieldset"]: {
      paddingLeft: "10px",
      borderRadius: "8px",
    },
  },
  inputLabelProps: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    paddingTop: "2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  fortgotPW: {
    marginTop: "-5px",
    fontSize: "14px",
    textAlign: "right",
    color: theme.palette.DARK_BLUE_HOVER,
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  signinBtn: {
    color: theme.palette.WHITE,
    backgroundColor: theme.palette.DARK_BLUE,
    borderRadius: "8px",
    fontSize: "14px",
    transition: "0.3s",
    padding: "6px 18px",
    marginTop: "10px",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.DARK_BLUE_HOVER,
      transition: "0.3s",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  notReg: {
    fontSize: "14px",
    marginTop: "8px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  createAccount: {
    color: theme.palette.DARK_BLUE_HOVER,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
