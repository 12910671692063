import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { GreyTextField } from "../helpers/CustomTextFields";
import { changePasswordStyles } from "./styles";

function ChangePassword() {
  const styleClasses = changePasswordStyles();

  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleChange = (event) => {
    const target = event.target;
    const id = target.id;
    const value = target.value;

    setState({ ...state, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className={styleClasses.root}>
      <form onSubmit={handleSubmit}>
        <GreyTextField
          id="currentPassword"
          className={styleClasses.inputField}
          InputLabelProps={{
            className: styleClasses.inputLabelProps,
          }}
          type="password"
          variant="outlined"
          label="Current password"
          size="small"
          onChange={handleChange}
          required
        />
        <GreyTextField
          id="newPassword"
          className={styleClasses.inputField}
          InputLabelProps={{
            className: styleClasses.inputLabelProps,
          }}
          type="password"
          variant="outlined"
          label="New password"
          size="small"
          onChange={handleChange}
          required
        />
        <GreyTextField
          id="confirmNewPassword"
          className={styleClasses.inputField}
          InputLabelProps={{
            className: styleClasses.inputLabelProps,
          }}
          type="password"
          variant="outlined"
          label="Confirm new password"
          size="small"
          onChange={handleChange}
          required
        />

        <div className={styleClasses.btnContainer}>
          <Button
            className={`${styleClasses.button} ${styleClasses.clrBtn}`}
            variant="contained"
            disableElevation
          >
            Clear
          </Button>
          <Button
            className={`${styleClasses.button} ${styleClasses.chngBtn}`}
            type="submit"
            variant="contained"
            disableElevation
          >
            Change password
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
