import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function FeedbackMsg(props) {
  const { open, handleClose, msg, severity, duration } = props;

  const displayAlert = () => {
    if (severity && severity?.length > 0) {
      return (
        <Alert onClose={handleClose} severity={severity} >
          {msg}
        </Alert>
      );
    }
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={duration ? duration : 6000}
        onClose={handleClose}
        message={msg}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      >
        {displayAlert()}
      </Snackbar>
    </div>
  );
}
