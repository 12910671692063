import React, { useState, useEffect } from "react";
import { Grid, AppBar, Toolbar, Typography, Button } from "@material-ui/core";
import { Popover, Paper, MenuList, MenuItem } from "@material-ui/core";
import brainLogo from "../../images/brainLogo.svg";
import { useHistory, useLocation } from "react-router-dom";
import theme from "../../../Theme";
import maleIcon from "../../images/maleProfile.svg";
import femaleIcon from "../../images/femaleProfile.svg";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { desktopNavBarStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser } from "../../../store/actions/authActions";
import CircularProgress from "@material-ui/core/CircularProgress";

function DesktopNavBar() {
  const styleClasses = desktopNavBarStyles();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { userData } = useSelector((state) => state.auth);

  const [activeItem, setActiveItem] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (isAuthenticated && userData) {
      setIsLoading(false);
    }
  }, [isAuthenticated, userData]);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setActiveItem("home");
        break;
      case "/about-us":
        setActiveItem("aboutUs");
        break;
      case "/profile/info":
        setActiveItem("profile");
        break;
      case "/profile/change-password":
        setActiveItem("profile");
        break;
      case "/pilot-study":
        setActiveItem("researchStudy");
        break;
      default:
        setActiveItem("");
    }
  }, [location.pathname]);

  const handleNavBarItemClick = (e, navItem) => {
    setActiveItem(navItem);
  };

  const goToHome = () => {
    setAnchorEl(null);
    history.push("/");
  };

  const goToSignIn = () => {
    setAnchorEl(null);
    history.push("/signIn");
  };

  const goToSignUp = () => {
    setActiveItem("");
    setAnchorEl(null);
    history.push("/signUp");
  };

  const goToProfile = () => {
    setActiveItem("profile");
    setAnchorEl(null);
    history.push("/profile/info");
  };

  const goToAboutUs = () => {
    setActiveItem("aboutUs");
    setAnchorEl(null);
    history.push("/about-us");
  };

  const goToResearchStudy = () => {
    setActiveItem("researchStudy");
    setAnchorEl(null);
    history.push("/pilot-study/description");
  };

  const logOut = () => {
    setAnchorEl(null);
    logOutUser()(dispatch);
    history.push("/");
  };

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const displayImage = () => {
    if (isLoading) {
      return (
        <div className={styleClasses.loadingContainer}>
          <CircularProgress
            size={"30px"}
            className={styleClasses.loadingIcon}
          />
        </div>
      );
    } else if (userData.gender === "female") {
      return (
        <img src={femaleIcon} className={styleClasses.maleIcon} alt="image" />
      );
    } else if (userData.gender === "male") {
      return (
        <img src={maleIcon} className={styleClasses.maleIcon} alt="image" />
      );
    }
  };

  const displaySignInOptions = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Button
            className={`${styleClasses.button} ${styleClasses.signInBtn}`}
            onClick={goToSignIn}
          >
            Sign in
          </Button>
        </>
      );
    } else {
      return (
        <>
          <div
            onClick={handleProfileClick}
            className={styleClasses.navbarProfile}
          >
            {displayImage()}
          </div>

          <Popover
            className={styleClasses.popover}
            open={menuOpen}
            anchorEl={anchorEl}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper className={styleClasses.popoverMenuPaper}>
              <MenuList className={styleClasses.popoverMenuList}>
                <MenuItem
                  classes={{
                    root: styleClasses.popoverMenuItem,
                    selected: styleClasses.selected,
                  }}
                  onClick={goToProfile}
                  selected={activeItem === "profile"}
                >
                  <AccountCircleIcon className={styleClasses.popoverMenuIcon} />
                  Profile
                </MenuItem>

                <MenuItem
                  classes={{
                    root: styleClasses.popoverMenuItem,
                    selected: styleClasses.selected,
                  }}
                  onClick={logOut}
                >
                  <ExitToAppIcon
                    className={`${styleClasses.popoverMenuIcon} ${styleClasses.signOutIcon}`}
                  />
                  Sign out
                </MenuItem>
              </MenuList>
            </Paper>
          </Popover>
        </>
      );
    }
  };

  return (
    <div className={styleClasses.root}>
      <AppBar position="static" className={styleClasses.navBarContainer}>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Toolbar className={styleClasses.toolbar}>
              <div
                className={styleClasses.logoWrapper}
                onClick={(e) => {
                  handleNavBarItemClick(e, "home");
                  goToHome();
                }}
              >
                <img
                  src={brainLogo}
                  className={styleClasses.brainLogo}
                  alt="image"
                />
              </div>

              <Typography
                variant="h6"
                className={styleClasses.title}
                onClick={(e) => {
                  handleNavBarItemClick(e, "home");
                  goToHome();
                }}
              >
                InnerAwareness
              </Typography>

              <Typography
                className={styleClasses.menuItem}
                style={{
                  color: activeItem === "home" && theme.palette.DARK_YELLOW,
                }}
                onClick={(e) => {
                  handleNavBarItemClick(e, "home");
                  goToHome();
                }}
              >
                Home
              </Typography>
              <Typography
                className={styleClasses.menuItem}
                style={{
                  color:
                    activeItem === "researchStudy" && theme.palette.DARK_YELLOW,
                }}
                onClick={(e) => {
                  handleNavBarItemClick(e, "researchStudy");
                  goToResearchStudy();
                }}
              >
                Research Study
              </Typography>
              <Typography
                className={styleClasses.menuItem}
                style={{
                  color: activeItem === "aboutUs" && theme.palette.DARK_YELLOW,
                }}
                onClick={(e) => {
                  handleNavBarItemClick(e, "aboutUs");
                  goToAboutUs();
                }}
              >
                About Us
              </Typography>
              {displaySignInOptions()}
            </Toolbar>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </AppBar>
    </div>
  );
}

export default DesktopNavBar;
