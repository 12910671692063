import React, { useState } from "react";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import { DarkBlueTextField } from "../helpers/CustomTextFields";
import google from "../images/googleLogo.svg";
import facebook from "../images/facebookLogo.svg";
import { useHistory } from "react-router-dom";
import { indexStyles } from "./styles";
import * as actions from "../../store/actions/actionTypes";
import { useDispatch } from "react-redux";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../utils/setAuthToken";

function Signin() {
  const styleClasses = indexStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [state, setstate] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: null,
    password: null,
  });

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const id = target.id;

    if (errors[id] !== null) {
      setErrors({
        ...errors,
        [id]: null,
      });
    }

    setstate({
      ...state,
      [id]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const res = await axios.post(`${BACKEND_URL}/users/login`, state);
      if (res?.data?.success) {
        setIsLoading(false);
        const { token } = res.data;
        const { userData } = res.data;

        localStorage.setItem("mindSpaceToken", token);
        localStorage.setItem("mindSpaceUser", JSON.stringify(userData));
        setAuthToken(token); // Set token to Auth header
        const decoded = jwt_decode(token);
        dispatch({
          type: actions.SET_USER_AUTH_DATA,
          payload: decoded,
        });
        dispatch({
          type: actions.SET_USER_DATA,
          payload: userData,
        });
        history.push("/");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 500) {
        setServerError(true);
      } else {
        if (error.response.data.errors) {
          setErrors(error.response.data.errors);
        }
      }
    }
  };

  const gotToSignup = () => {
    history.push("/signup");
  };

  return (
    <>
      <div className={styleClasses.root}>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item container xs={10} justifyContent="center">
            <Paper elevation={2} className={styleClasses.paper}>
              <div className={styleClasses.formContainer}>
                <div className={styleClasses.welcomeTextContainer}>
                  <Typography className={styleClasses.welcomeText}>
                    Welcome to InnerAwareness
                  </Typography>
                  <Typography className={styleClasses.welcomeSubText}>
                    Please enter your details to sign in.
                  </Typography>
                </div>

                <form onSubmit={handleSubmit}>
                  <DarkBlueTextField
                    id="email"
                    className={styleClasses.inputField}
                    InputLabelProps={{
                      className: styleClasses.inputLabelProps,
                    }}
                    variant="outlined"
                    label="Email"
                    size="small"
                    onChange={handleChange}
                    error={errors.email ? true : false}
                    helperText={errors.email}
                    required
                  />

                  <DarkBlueTextField
                    id="password"
                    className={styleClasses.inputField}
                    InputLabelProps={{
                      className: styleClasses.inputLabelProps,
                    }}
                    variant="outlined"
                    label="Password"
                    size="small"
                    type="password"
                    onChange={handleChange}
                    error={errors.password ? true : false}
                    helperText={errors.password}
                    required
                  />

                  <div className={styleClasses.btnContainer}>
                    <Button
                      className={`${styleClasses.button} ${styleClasses.signinBtn}`}
                      type="submit"
                      variant="contained"
                      disableElevation
                      disabled={isLoading}
                    >
                      Sign in
                    </Button>
                  </div>
                </form>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
    </>
  );
}

export default Signin;
