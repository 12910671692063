import React from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { errorStyles } from "./styles";
import { Typography, Button } from "@material-ui/core";

function Error() {
  const styleClasses = errorStyles();

  return (
    <>
      <div className={styleClasses.root}>
        <ErrorOutlineIcon></ErrorOutlineIcon>
        <Typography className={styleClasses.errorMsg}>
          Something is not quite right. Please try again later.
        </Typography>
      </div>
    </>
  );
}

export default Error;
