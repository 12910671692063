import { makeStyles } from "@material-ui/core/styles";

export const indexStyles = makeStyles((theme) => ({
  root: {
    padding: "20px 20px",
    backgroundColor: theme.palette.DARK_BLUE,
    color: theme.palette.LIGHT_GREY,
    textAlign: "center",
  },
  mainText: {
    fontSize: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  highlight: {
    color: theme.palette.DARK_YELLOW,
  },
  listContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  listItem: {
    marginRight: "15px",
    fontSize: "15px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.DARK_YELLOW,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  listIcon: {
    marginRight: "15px",
    fontSize: "20px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.DARK_YELLOW,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
      marginRight: "12px",
    },
  },
}));
