import { makeStyles } from "@material-ui/core/styles";

export const indexStyles = makeStyles((theme) => ({
  loadingRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    marginTop: "-50px",
  },
  container: {
    border: `1px solid ${theme.palette.MID_GREY}`,
    borderRadius: "10px",
    padding: "30px 60px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 30px",
    },
  },
  lastContainer: {
    marginBottom: "0px",
  },
  title: {
    fontSize: "15px",
    fontFamily: "Roboto-Medium",
    marginBottom: "6px",
  },
  greyText: {
    color: theme.palette.DARK_GREY,
    fontSize: "14px",
  },
  btnContainer: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    fontSize: "14px",
  },
  cancelBtn: {
    fontSize: "14px",
    padding: "5px 12px",
    marginRight: "10px",
    "&:hover": {
      boxShadow: "0px 0px",
      backgroundColor: theme.palette.background.paper,
      transition: "0.5s",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  submitBtn: {
    boxShadow: "0px 0px",
    fontSize: "14px",
    padding: "8px 12px",
    "&:hover": {
      boxShadow: "0px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));
