import React, { useEffect, useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { indexStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { BACKEND_URL } from "../../../config";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import MultipleChoiceSingle from "../Questions/MultipleChoiceSingle";
import MultipleChoiceMulti from "../Questions//MultipleChoiceMulti";
import ParagraphQuestion from "../Questions//ParagraphQuestion";
import { useSelector } from "react-redux";
import SaveIcon from "@material-ui/icons/Save";
import FeedbackMsg from "../../helpers/FeedbackMsg";
import Error from "../Error/Error";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";

function PreQuestionnaire({ setPreQCompleted }) {
  const styleClasses = indexStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userAuthData } = useSelector((state) => state.auth);
  const handleAuthFail = useHandleAuthFail();
  const { preQAnswers: answers } = useSelector((state) => state.preQ);

  // for feedback msg
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("");

  // complete modal
  const [modalOpen, setModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [submitted, setSubmitted] = useState(null);
  const [isError, setIsError] = useState(false);
  const [errors, setErrors] = useState([]);

  const goToPostQuestionnaire = () => {
    history.push("/pilot-study/post-analysis/1");
  };

  useEffect(() => {
    retrievePreQuestions();
  }, []);

  // for feedback msg
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  // complete modal
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const retrievePreQuestions = async () => {
    setIsError(false);
    try {
      const response = await axios.get(
        `${BACKEND_URL}/pre-questionnaire/${userAuthData.id}`
      );
      if (response.data.success) {
        setQuestions(response.data.questions);
        setErrors(new Array(response.data.questions.length).fill(""));
        dispatch({
          type: actions.SET_PRE_QUESTIONS,
          payload: response.data.answers,
        });
        setSubmitted(response.data.submitted);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleAuthFail();
        return;
      }
      setIsLoading(false);
      setIsError(true);
    }
  };

  const handleSaveProgress = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsError(false);
    setSeverity("");
    try {
      const response = await axios.put(
        `${BACKEND_URL}/pre-questionnaire/save/${userAuthData.id}`,
        {
          answers,
        }
      );
      if (response.data.success) {
        setIsSubmitted(false);
        setMsg("Your answers have been saved.");
        setOpen(true);
        setPreQCompleted(response.data.completed);
        setErrors(new Array(questions.length).fill(""));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleAuthFail();
        return;
      }
      setIsSubmitted(false);
      if (error.response && error.response.status === 500) {
        setIsError(true);
      } else {
        if (error.response.data.errors) {
          setErrors(error.response.data.errors);
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsError(false);
    setModalOpen(false);
    setSeverity("");
    try {
      const response = await axios.put(
        `${BACKEND_URL}/pre-questionnaire/submit/${userAuthData.id}`,
        {
          answers,
        }
      );
      if (response.data.success) {
        setIsSubmitted(false);
        setMsg("Your answers have been saved.");
        setOpen(true);
        setPreQCompleted(response.data.completed);
        setErrors(new Array(questions.length).fill(""));
        setSubmitted(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleAuthFail();
        return;
      }
      setIsSubmitted(false);
      if (error.response && error.response.status === 500) {
        setIsError(true);
      } else {
        if (error.response.data.errors) {
          setErrors(error.response.data.errors);
        }
      }
      setMsg(
        "Answers could not be submitted. Please check all your answers again."
      );
      setOpen(true);
      setSeverity("error");
    }
  };

  const displayQuestions = () => {
    if (isLoading) {
      return (
        <div className={styleClasses.loadingRoot}>
          <CircularProgress></CircularProgress>
        </div>
      );
    } else if (isError) {
      return <Error />;
    } else if (questions?.length > 0 && answers?.length > 0) {
      return (
        <>
          <div className={`${styleClasses.container}`}>
            <Typography className={styleClasses.title}>
              Pre - Questionnaire
            </Typography>
            <div>
              <Typography className={styleClasses.greyText}>
                It is mandatory to complete all the questions ( Estimated time -
                maximum 15 minutes )
              </Typography>
            </div>
          </div>

          <form>
            {questions.map((q, index, arr) => {
              return (
                <div
                  key={q["_id"]["$oid"]}
                  className={
                    index + 1 === arr.length
                      ? `${styleClasses.container} ${styleClasses.lastContainer}`
                      : `${styleClasses.container}`
                  }
                >
                  {q.type === "multipleChoice-single" && (
                    <MultipleChoiceSingle
                      questionNo={index + 1}
                      question={q}
                      answers={answers}
                      answerIndex={index}
                      errors={errors}
                      setErrors={setErrors}
                      submitted={submitted}
                      isSubmitted={isSubmitted}
                      handleSaveProgress={handleSaveProgress}
                      ACTION_TYPE={actions.SET_PRE_QUESTIONS}
                    ></MultipleChoiceSingle>
                  )}
                  {q.type === "multipleChoice-multi" && (
                    <MultipleChoiceMulti
                      questionNo={index + 1}
                      question={q}
                      answers={answers}
                      answerIndex={index}
                      errors={errors}
                      setErrors={setErrors}
                      submitted={submitted}
                      isSubmitted={isSubmitted}
                      handleSaveProgress={handleSaveProgress}
                      ACTION_TYPE={actions.SET_PRE_QUESTIONS}
                    ></MultipleChoiceMulti>
                  )}
                  {q.type === "paragraph" && (
                    <ParagraphQuestion
                      questionNo={index + 1}
                      question={q}
                      answers={answers}
                      answerIndex={index}
                      errors={errors}
                      setErrors={setErrors}
                      submitted={submitted}
                      isSubmitted={isSubmitted}
                      handleSaveProgress={handleSaveProgress}
                      ACTION_TYPE={actions.SET_PRE_QUESTIONS}
                    ></ParagraphQuestion>
                  )}
                </div>
              );
            })}

            {!submitted && (
              <div className={styleClasses.btnContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  className={`${styleClasses.button} ${styleClasses.submitBtn}`}
                  disabled={isSubmitted}
                  onClick={handleModalOpen}
                >
                  Submit answers & Go to Post Analysis
                </Button>
              </div>
            )}

            {submitted && (
              <div className={styleClasses.btnContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  className={`${styleClasses.button} ${styleClasses.submitBtn}`}
                  disabled={isSubmitted}
                  onClick={goToPostQuestionnaire}
                >
                  Next
                </Button>
              </div>
            )}

            <Dialog
              open={modalOpen}
              onClose={handleModalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Do you want to proceed to post analysis?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Once you submit the pre-questionnaire and proceed to the post
                  analysis, you cannot change your answers for the
                  pre-questionnaire. Are you sure?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                  Cancel
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </>
      );
    }
  };

  return (
    <div>
      <FeedbackMsg
        open={open}
        handleClose={handleClose}
        msg={msg}
        severity={severity}
        duration={12000}
      ></FeedbackMsg>
      {displayQuestions()}
    </div>
  );
}

export default PreQuestionnaire;
