import React, { useState } from "react";
import { predictionChartStyles } from "./styles";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale } from "chart.js";
import { Title, Tooltip, Legend, BarElement } from "chart.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText } from "@material-ui/core";
import { Typography, Button, Box } from "@material-ui/core";
import { DarkRedRadio } from "../../helpers/RadioButton";
import { RedRadio, YellowRadio } from "../../helpers/RadioButton";
import { GreenRadio, DarkGreenRadio } from "../../helpers/RadioButton";
import { GreyTextField } from "../../helpers/CustomTextFields";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { useSelector, useDispatch } from "react-redux";
import FeedbackMsg from "../../helpers/FeedbackMsg";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";
import MuiTooltip from "@material-ui/core/Tooltip";
import * as actions from "../../../store/actions/actionTypes";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function PredictionChart(props) {
  const { submitted } = props;
  const { setIsError, setAnalysisCompleted } = props;

  const { questionNo } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const { userAuthData } = useSelector((state) => state.auth);
  const { answers } = useSelector((state) => state.analysis);
  const { question } = useSelector((state) => state.analysis);

  const { summaryAgreed } = useSelector((state) => state.analysisInput);
  const { summaryComment } = useSelector((state) => state.analysisInput);
  const { chartAgreed } = useSelector((state) => state.analysisInput);
  const { chartComment } = useSelector((state) => state.analysisInput);

  const handleAuthFail = useHandleAuthFail();

  const styleClasses = predictionChartStyles();

  // for feedback msg
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const [agreeError, setAgreeError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // for feedback msg
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const handleChange = (event) => {
    dispatch({
      type: actions.SET_CHART_AGREED,
      payload: event.target.value,
    });
    setAgreeError(false);
  };

  const handleComment = (event, index) => {
    dispatch({
      type: actions.SET_CHART_COMMENT,
      payload: event.target.value,
    });
    setCommentError(false);
  };

  const handleChartSubmit = async (e) => {
    e.preventDefault();
    const newAnswers = [...answers];
    newAnswers[questionNo - 1].summaryAgreed = summaryAgreed;
    newAnswers[questionNo - 1].summaryComment = summaryComment.trim();
    newAnswers[questionNo - 1].chartAgreed = chartAgreed;
    newAnswers[questionNo - 1].chartComment = chartComment.trim();
    dispatch({
      type: actions.SET_ANALYSIS_ANSWERS,
      payload: newAnswers,
    });

    if (userAuthData.id && !agreeError && !commentError) {
      setIsError(false);
      setIsSubmitted(true);
      try {
        const response = await axios.put(
          `${BACKEND_URL}/post-analysis/chart/${userAuthData.id}/${questionNo}`,
          { summaryAgreed, summaryComment, chartAgreed, chartComment }
        );
        if (response.data.success) {
          setIsSubmitted(false);
          setMsg("Your answers have been saved.");
          setOpen(true);
          setAnalysisCompleted(response.data.completed);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleAuthFail();
          return;
        }
        setIsSubmitted(false);
        if (error.response && error.response.status === 500) {
          setIsError(true);
        } else {
          if (error.response?.data.error === "agree") {
            setAgreeError(true);
          } else if (error.response?.data.error === "comment") {
            setCommentError(true);
          }
        }
      }
    }
  };

  const labels = [
    "Mental illness",
    "Sad",
    "Anxiety/Stress",
    "Suicidal",
    "Anger",
    "Psychosomatic",
  ];

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
    },
  };

  const tooltipDetails = [
    {
      label: "Mental illness",
      description: `The post includes a diagnosis of a mental illness (e.g. depression),
         getting treatments for a mental illness, or self-identification of a mental illness 
         based on history and seek help.`,
      style: styleClasses.mi,
    },
    {
      label: "Sadness",
      description: `The post includes sadness, unhappy or sorry that may lead to a maladaptive 
          mental condition or mental illness.`,
      style: styleClasses.sad,
    },
    {
      label: "Anxiety/stress",
      description:
        "The post includes stress, fear, worry about something that may lead to a maladaptive mental condition or mental illness.",
      style: styleClasses.anxiety,
    },
    {
      label: "Suicidal thoughts",
      description: `The post includes suicidal thoughts or no interest in life.`,
      style: styleClasses.suicidal,
    },
    {
      label: "Anger",
      description: `The post includes anger that may lead to a maladaptive mental condition or mental illness.`,
      style: styleClasses.anger,
    },
    {
      label: "Psychosomatic",
      description: `The post includes psychosomatic issues (e.g. insomnia, fatigue, headaches, 
          upset stomach) that associated with underlying mental distress or may lead to a maladaptive
           mental condition or mental illness.`,
      style: styleClasses.psycho,
    },
  ];

  const generateBarChart = () => {
    return {
      labels: labels,
      datasets: [
        {
          label: "",
          borderColor: [
            "#4bab7c",
            "#545454",
            "#3f72c4",
            "#9f6bc7",
            "#943237",
            "#3d6e60",
          ],
          backgroundColor: [
            "#50c38b",
            "#666666",
            "#4a86e8",
            "#c77dff",
            "#c7434a",
            "#3d7364",
          ],
          borderWidth: 1,
          data: [
            question.details.MI,
            question.details.SD,
            question.details.AS,
            question.details.SC,
            question.details.AG,
            question.details.PY,
          ],
        },
      ],
    };
  };

  return (
    <>
      <FeedbackMsg
        open={open}
        handleClose={handleClose}
        msg={msg}
      ></FeedbackMsg>

      <Bar data={generateBarChart()} options={options} />

      <div className={styleClasses.tooltipContainer}>
        {tooltipDetails.map((i) => (
          <MuiTooltip
            key={i.label}
            title={
              <Typography className={styleClasses.tooltipText}>
                {i.description}
              </Typography>
            }
          >
            <div className={styleClasses.tooltip}>
              <div className={`${styleClasses.legendBox} ${i.style}`}></div>
              <Typography className={styleClasses.legendLabel}>
                {i.label}
              </Typography>
            </div>
          </MuiTooltip>
        ))}
      </div>

      <form onSubmit={handleChartSubmit}>
        <div className={styleClasses.agreeContainer}>
          <Typography className={styleClasses.agreeText}>
            Do you agree?
          </Typography>

          <FormControl
            component="fieldset"
            className={styleClasses.formControl}
            error={agreeError}
          >
            {agreeError && (
              <FormHelperText>Please select an answer.</FormHelperText>
            )}
            <RadioGroup
              row={!isMobile}
              aria-label="agree"
              name="agree"
              value={chartAgreed}
              onChange={handleChange}
            >
              <FormControlLabel
                className={styleClasses.formControlLabel}
                value="stronglyAgree"
                control={
                  <DarkGreenRadio
                    className={styleClasses.radio}
                    disabled={submitted}
                  />
                }
                label={
                  <Typography className={styleClasses.radioLabel}>
                    Strongly agree
                  </Typography>
                }
              />
              <FormControlLabel
                className={styleClasses.formControlLabel}
                value="agree"
                control={
                  <GreenRadio
                    className={styleClasses.radio}
                    disabled={submitted}
                  />
                }
                label={
                  <Typography className={styleClasses.radioLabel}>
                    Agree
                  </Typography>
                }
              />
              <FormControlLabel
                className={styleClasses.formControlLabel}
                value="neutral"
                control={
                  <YellowRadio
                    className={styleClasses.radio}
                    disabled={submitted}
                  />
                }
                label={
                  <Typography className={styleClasses.radioLabel}>
                    Neutral
                  </Typography>
                }
              />
              <FormControlLabel
                className={styleClasses.formControlLabel}
                value="disagree"
                control={
                  <RedRadio
                    className={styleClasses.radio}
                    disabled={submitted}
                  />
                }
                label={
                  <Typography className={styleClasses.radioLabel}>
                    Disagree
                  </Typography>
                }
              />
              <FormControlLabel
                className={styleClasses.formControlLabel}
                value="stronglyDisagree"
                control={
                  <DarkRedRadio
                    className={styleClasses.radio}
                    disabled={submitted}
                  />
                }
                label={
                  <Typography className={styleClasses.radioLabel}>
                    Strongly disagree
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>

          <div className={styleClasses.textFieldContainer}>
            <GreyTextField
              id="comment"
              label="Please provide your feedback"
              multiline
              className={styleClasses.inputField}
              InputLabelProps={{
                className: styleClasses.inputLabelProps,
              }}
              value={chartComment}
              variant="outlined"
              size="small"
              rows={3}
              onChange={handleComment}
              error={commentError}
              helperText={
                commentError &&
                "Feedback cannot be empty. Please provide your feedback."
              }
              disabled={submitted}
              required
            />

            {!submitted && (
              <div className={styleClasses.btnContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={`${styleClasses.button} ${styleClasses.submitBtn}`}
                  disabled={isSubmitted}
                >
                  Submit Response
                </Button>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default PredictionChart;
