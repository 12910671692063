import React from "react";
import { Typography, SvgIcon } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { useMediaQuery } from "react-responsive";
import { indexStyles } from "./styles";
import { useHistory } from "react-router-dom";

function Footer() {
  const styleClasses = indexStyles();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 750px)" });

  const goToAboutUs = () => {
    history.push("/about-us");
  };

  return (
    <div className={styleClasses.root}>
      <Typography className={styleClasses.mainText}>
        Copyright <span className={styleClasses.highlight}>InnerAwareness</span>{" "}
        © 2022 {isMobile ? <br /> : "-"} All rights reserved.
      </Typography>

      <div className={styleClasses.listContainer}>
        <div>
          <SvgIcon className={styleClasses.listIcon} component={TwitterIcon} />
          <SvgIcon className={styleClasses.listIcon} component={LinkedInIcon} />
        </div>

        <Typography className={styleClasses.listItem} onClick={goToAboutUs}>
          About Us
        </Typography>
        <Typography className={styleClasses.listItem}>
          Terms and Conditions
        </Typography>
        <Typography
          className={styleClasses.listItem}
          style={{ marginRight: "0px" }}
        >
          Privacy Policy
        </Typography>
      </div>
    </div>
  );
}

export default Footer;
