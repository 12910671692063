import React from "react";
import { Typography, Button } from "@material-ui/core";
import { indexStyles } from "./styles";
import notFound from "../images/notFound.svg";
import { useHistory } from "react-router-dom";

function NotFound() {
  const styleClasses = indexStyles();
  const history = useHistory();

  const goToHome = () => {
    history.push("/");
  };

  return (
    <>
      <div className={styleClasses.root}>
        <div className={styleClasses.imageContainer}>
          <img src={notFound} className={styleClasses.image} alt="image" />
        </div>
        <Typography className={styleClasses.text}>
          We are sorry, the page you requested could not be found.
          <br /> Please go back to home page.
        </Typography>
        <Button className={styleClasses.homeButton} onClick={goToHome}>
          Home Page
        </Button>
      </div>
    </>
  );
}

export default NotFound;
