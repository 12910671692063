import { makeStyles } from "@material-ui/core/styles";

export const aboutStudyStyles = makeStyles((theme) => ({
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepper: {
    width: "80%",
    borderRadius: "10px",
    padding: "40px 60px",
    border: `1px solid ${theme.palette.MID_GREY}`,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "20px 15px",
    },
  },
  stepLabel: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  stepDescription: {
    fontSize: "14px",
  },
  contact: {
    color: "#5e5ee6",
  },
  instructionsTitle: {
    fontSize: "16px",
    marginTop: "30px",
    fontFamily: "Roboto-Bold",
  },
  instructionsText: {
    marginTop: "10px",
    fontSize: "14px",
  },
  olist: {
    fontFamily: "Roboto-regular",
    fontSize: "14px",
  },
  olistItem: {
    marginBottom: "10px",
  },
  ulist: {
    marginTop: "10px",
    listStyleType: "disc",
  },
  ulistItem: {
    marginBottom: "10px",
  },
  stepperBtnContainer: {
    marginTop: "16px",
    display: "flex",
    justifyContent: "flex-end",
  },
  catDefinitionTitle: {
    fontSize: "16px",
    fontFamily: "Roboto-Bold",
    marginTop: "20px",
  },
  divider: {
    marginTop: "5px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6px",
    },
  },
  categoryTitle: {
    fontSize: "14px",
    fontFamily: "Roboto-Bold",
    marginTop: "10px",
  },
  categoryText: {
    fontSize: "14px",
    fontFamily: "Roboto-regular",
  },
  backBtn: {
    fontSize: "14px",
    padding: "5px 18px",
  },
  nextBtn: {
    color: `${theme.palette.WHITE}`,
    backgroundColor: theme.palette.primary.dark,
    border: `2px solid ${theme.palette.primary.dark}`,
    boxShadow: "0px 0px",
    fontSize: "14px",
    transition: "0.5s",
    padding: "5px 18px",
    "&:hover": {
      boxShadow: "0px 0px",
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.background.paper,
      transition: "0.5s",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));
