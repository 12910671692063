import React from "react";
import { Typography, Button } from "@material-ui/core";
import { paragraphStyles } from "./styles";
import { formatNumber } from "../../../utils/helpers";
import { GreyTextField } from "../../helpers/CustomTextFields";
import SaveIcon from "@material-ui/icons/Save";
import { useDispatch } from "react-redux";

function ParagraphQuestion(props) {
  const { answers, questionNo, question, answerIndex } = props;
  const { errors, setErrors, submitted, ACTION_TYPE } = props;
  const { isSubmitted, handleSaveProgress } = props;
  const dispatch = useDispatch();

  const styleClasses = paragraphStyles();

  const handleChange = (event) => {
    const value = event.target.value;
    const newAnswers = [...answers];

    newAnswers[answerIndex]["answer"] = value;
    dispatch({
      type: ACTION_TYPE,
      payload: newAnswers,
    });

    const newErrors = [...errors];
    newErrors[questionNo - 1] = "";
    setErrors(newErrors);
  };

  const disableInput = () => {
    if ("requiredQuestion" in question) {
      if (
        answers[question.requiredQuestion - 1]["answers"][3]["selected"] ===
          true ||
        answers[question.requiredQuestion - 1]["answers"][4]["selected"] ===
          true
      ) {
        return false || isSubmitted || submitted;
      }
      return true || isSubmitted || submitted;
    }
    return isSubmitted || submitted;
  };

  const displayValue = () => {
    if ("requiredQuestion" in question) {
      if (
        answers[question.requiredQuestion - 1]["answers"][3]["selected"] ===
          true ||
        answers[question.requiredQuestion - 1]["answers"][4]["selected"] ===
          true
      ) {
        return answers[answerIndex]["answer"];
      }
      return "";
    }
    return answers[answerIndex]["answer"];
  };

  return (
    <>
      <Typography className={styleClasses.title}>
        Question {formatNumber(questionNo)}
      </Typography>
      <div className={styleClasses.content}>
        <Typography className={styleClasses.question}>
          {question.question}
        </Typography>

        <div>
          <GreyTextField
            id="answer"
            multiline
            className={styleClasses.inputField}
            InputLabelProps={{
              className: styleClasses.inputLabelProps,
            }}
            variant="outlined"
            size="small"
            onChange={handleChange}
            value={displayValue()}
            error={errors[answerIndex] === "" ? false : true}
            helperText={errors[answerIndex]}
            disabled={disableInput()}
          />
        </div>
      </div>
      <div className={styleClasses.btnContainer}>
        <Button
          edge="start"
          className={`${styleClasses.button} ${styleClasses.saveButton}`}
          color="inherit"
          variant="contained"
          onClick={handleSaveProgress}
          disabled={disableInput()}
        >
          <SaveIcon className={styleClasses.saveIcon} />
          <Typography className={styleClasses.saveText}>
            Save Current Progress
          </Typography>
        </Button>
      </div>
    </>
  );
}

export default ParagraphQuestion;
