import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { indexStyles } from "./styles";
import Error from "../Error/Error";
import NotComplete from "../Error/NotComplete";
import Lottie from "react-lottie";
import Success from "../../Lotties/success_green.json";
import { BACKEND_URL } from "../../../config";
import axios from "axios";
import { useSelector } from "react-redux";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";

function Completion() {
  const { userAuthData } = useSelector((state) => state.auth);
  const handleAuthFail = useHandleAuthFail();

  const styleClasses = indexStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [completed, setCompleted] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    retrieveSubmittedDetails();
  }, []);

  const retrieveSubmittedDetails = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_URL}/pilotStudy/submitted/${userAuthData.id}`
      );
      if (response?.data.success) {
        if (response.data.preQ && response.data.analysis && response.data.feedback) {
          setCompleted(true);
        } else {
          setCompleted(false);
        }
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleAuthFail();
        return;
      }
      setIsLoading(false);
      setIsError(true);
    }
  };

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: Success,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const displaySuccess = () => {
    if (isLoading) {
      return (
        <div className={styleClasses.loadingRoot}>
          <CircularProgress></CircularProgress>
        </div>
      );
    } else if (isError) {
      return <Error />;
    } else if (completed === false) {
      return (
        <div className={styleClasses.container}>
          <NotComplete
            msg={"Please submit all the questionnaires to view the results."}
          />
        </div>
      );
    } else if (completed === true) {
      return (
        <div className={styleClasses.container}>
          <div className={styleClasses.flex}>
            <Lottie options={successOptions} height={100} width={100} />
            <Typography className={styleClasses.text}>
              Thank you for completing the study!
            </Typography>
            <Typography className={styleClasses.text}>
              You will receive an email from us within next couple of days on
              how to claim your compensation.
            </Typography>
          </div>
        </div>
      );
    }
  };

  return <div>{displaySuccess()}</div>;
}

export default Completion;
