import React, { useState } from "react";
import { Typography, FormControl, Divider } from "@material-ui/core";
import { Button, RadioGroup, FormControlLabel } from "@material-ui/core";
import { GreyTextField } from "../helpers/CustomTextFields";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { editUserInfoStyles } from "./styles";
import { useSelector } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import maleProfile from "../images/maleProfile.svg";
import femaleProfile from "../images/femaleProfile.svg";
import { BlueRadio } from "../helpers/RadioButton";
import { capitalizeFirstLetter, formatDate } from "../../utils/helpers";
import { BACKEND_URL } from "../../config";
import axios from "axios";
import { useDispatch } from "react-redux";
import * as actions from "../../store/actions/actionTypes";
import useHandleAuthFail from "../../utils/useHandleAuthFail";
import FeedbackMsg from "../helpers/FeedbackMsg";

function UserInfo() {
  const styleClasses = editUserInfoStyles();
  const { userData, userAuthData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleAuthFail = useHandleAuthFail();

  // for feedback msg
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [state, setState] = useState({
    gender: userData?.gender,
    designation: userData?.designation,
  });
  const [selectedDate, setSelectedDate] = useState(userData?.dob);
  const [isLoading, setIsLoading] = useState(false);

  // for feedback msg
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const id = target.id;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setState({ ...state, [id]: value });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleInfoSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${BACKEND_URL}/users/info/${userAuthData.id}`,
        {
          ...state,
          dob: selectedDate,
        }
      );
      if (response.data.success) {
        setIsLoading(false);
        dispatch({
          type: actions.SET_USER_DATA,
          payload: { ...state, dob: selectedDate },
        });
        localStorage.setItem(
          "mindSpaceUser",
          JSON.stringify({ ...state, dob: selectedDate })
        );
        setMsg("Your answers have been saved.");
        setOpen(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleAuthFail();
        return;
      }
      setIsLoading(false);
    }
  };

  return (
    <div className={styleClasses.root}>
      <FeedbackMsg
        open={open}
        handleClose={handleClose}
        msg={msg}
        severity={severity}
        duration={12000}
      ></FeedbackMsg>

      <div className={styleClasses.section1}>
        <div className={styleClasses.infoTop}>
          {userData.gender === "female" ? (
            <img
              src={femaleProfile}
              className={styleClasses.profileIcon}
              alt="image"
            />
          ) : (
            <img
              src={maleProfile}
              className={styleClasses.profileIcon}
              alt="image"
            />
          )}

          <div className={styleClasses.textContainer}>
            <Typography className={styleClasses.email}>
              {userAuthData?.email}
            </Typography>

            <div>
              <Button
                className={`${styleClasses.button} ${styleClasses.editProfile}`}
                variant="contained"
                disableElevation
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <EditIcon className={styleClasses.editIcon} />{" "}
                <Typography className={styleClasses.editText}>
                  Edit profile
                </Typography>
              </Button>
            </div>
          </div>
        </div>
        <Divider />
      </div>

      {isEditing && (
        <div className={styleClasses.section2}>
          <form onSubmit={handleInfoSubmit}>
            <div className={styleClasses.infoContainer}>
              <Typography
                className={`${styleClasses.infoTitle} ${styleClasses.infoTitleEditing}`}
              >
                Gender
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup row value={state.gender} onChange={handleChange}>
                  <FormControlLabel
                    value="male"
                    className={styleClasses.formControlLabel}
                    control={
                      <BlueRadio id="gender" className={styleClasses.radio} />
                    }
                    label={
                      <Typography className={styleClasses.radioLabel}>
                        Male
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="female"
                    control={
                      <BlueRadio id="gender" className={styleClasses.radio} />
                    }
                    label={
                      <Typography className={styleClasses.radioLabel}>
                        Female
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="other"
                    control={
                      <BlueRadio id="gender" className={styleClasses.radio} />
                    }
                    label={
                      <Typography className={styleClasses.radioLabel}>
                        Other
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className={styleClasses.infoContainer}>
              <Typography
                className={`${styleClasses.infoTitle} ${styleClasses.infoTitleEditing}`}
              >
                Date of birth
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableFuture
                  disableToolbar
                  id="dob"
                  openTo="year"
                  format="dd/MM/yyyy"
                  views={["year", "month", "date"]}
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              className={`${styleClasses.infoContainer} ${styleClasses.textFieldContainer}`}
            >
              <GreyTextField
                id="designation"
                className={styleClasses.inputField}
                InputLabelProps={{
                  className: styleClasses.inputLabelProps,
                }}
                onChange={handleChange}
                variant="outlined"
                size="small"
                label="Designation"
                value={state.designation}
                required
              />
            </div>
            <div className={styleClasses.btnContainer}>
              <Button
                className={`${styleClasses.button} ${styleClasses.cancelBtn}`}
                type="submit"
                variant="contained"
                disableElevation
                onClick={() => {
                  setIsEditing(false);
                }}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                className={`${styleClasses.button} ${styleClasses.saveBtn}`}
                type="submit"
                variant="contained"
                disableElevation
                disabled={isLoading}
              >
                Save changes
              </Button>
            </div>
          </form>
        </div>
      )}
      {!isEditing && (
        <div className={styleClasses.section2}>
          <div className={styleClasses.infoContainer}>
            <Typography className={styleClasses.infoTitle}>Gender</Typography>
            <Typography className={styleClasses.infoData}>
              {capitalizeFirstLetter(userData?.gender)}
            </Typography>
          </div>

          <div className={styleClasses.infoContainer}>
            <Typography className={styleClasses.infoTitle}>
              Date of birth
            </Typography>
            <Typography className={styleClasses.infoData}>
              {formatDate(userData?.dob)}
            </Typography>
          </div>

          <div className={styleClasses.finalContainer}>
            <Typography className={styleClasses.infoTitle}>
              Designation
            </Typography>
            <Typography className={styleClasses.infoData}>
              {capitalizeFirstLetter(userData?.designation)}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserInfo;
