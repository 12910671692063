import React from "react";
import { indexStyles } from "./styles";
import { Container, Grid, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Divider } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import { useMediaQuery } from "react-responsive";

function AboutUs() {
  const styleClasses = indexStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const temMembers = [
    "Professor Piyanjali de Zoysa (Professor in Clinical Psychology, Department of Psychiatry, University of Colombo, Sri Lanka)",
    "Dr Kasun Gunawardena (Senior Lecturer, University of Colombo School of Computing, Sri Lanka)",
    "Dr Menasha Thilakaratne (Lecturer, School of Computer Science, The University of Adelaide, Australia)",
    "Mr Charith Elvitigala (Researcher, SCoRe lab, University of Colombo School of Computing, Sri Lanka",
  ];

  return (
    <>
      <div className={styleClasses.root}>
        <Container maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}></Grid>
            <Grid item xs={12} lg={8}>
              <div className={styleClasses.container}>
                <div className={styleClasses.section}>
                  <Typography
                    variant="h1"
                    className={styleClasses.sectionTitle}
                  >
                    About Us
                  </Typography>
                  <Divider className={styleClasses.divider} />

                  <Typography>
                    Mental health issues remain a leading cause for poor
                    well-being globally. In 2018, the World Health Organization
                    (WHO) estimated that over 400 million people around the
                    world would be affected by mental disorders like depression
                    and anxiety, and these numbers are growing exponentially as
                    a result of the COVID-19 pandemic - primarily due to
                    lockdowns, social isolation etc.
                    <br />
                    <br />
                    People increasingly use digital media such as social media
                    groups and online forums to express and exchange their
                    psychological distress and to seek relief. InnerAwareness is
                    a tool designed to analyze users" language (e.g. social
                    media posts, statuses) expressed in digital media and to
                    predict their likelihood of developing a mental illness or
                    maladaptive mental condition, using computer-based
                    Artificial Intelligence (AI) technology. The tool also
                    predicts emotions (e.g. sadness, anxiety), mental states
                    (e.g. suicidal thoughts), and psychosomatic issues. The
                    ultimate goal of this tool is to provide a tool (either
                    web-based or app-based system) for individuals to assess and
                    develop self-awareness of their mental wellbeing, across
                    time.
                    <br />
                    <br />
                    This project is a collaboration between the University of
                    Adelaide, Australia and the University of Colombo, Sri
                    Lanka. It is funded by the Australian Academy of Science and
                    the Australian Department of Industry, Science, Energy and
                    Resources under its “Regional Collaborations Programme
                    COVID-19 Digital Grants”.
                  </Typography>
                </div>

                <div className={styleClasses.section}>
                  <Typography
                    variant="h1"
                    className={styleClasses.sectionTitle}
                  >
                    Project Team
                  </Typography>
                  <Divider className={styleClasses.divider} />

                  <div className={styleClasses.teamContainer}>
                    <Typography variant="h1" className={styleClasses.teamTitle}>
                      Project Lead
                    </Typography>

                    <List dense={true}>
                      <ListItem className={styleClasses.listItem}>
                        <ListItemIcon>
                          <FiberManualRecordIcon
                            className={styleClasses.listDot}
                          />
                        </ListItemIcon>
                        <Typography className={styleClasses.listText}>
                          Dr Thushari Atapattu (Lead, Language Technology for
                          Social Good Research Group, The University of
                          Adelaide, Australia)
                        </Typography>
                      </ListItem>
                    </List>
                  </div>

                  <div className={styleClasses.teamContainer}>
                    <Typography variant="h1" className={styleClasses.teamTitle}>
                      Project lead (Sri Lanka)
                    </Typography>
                    <List dense={true}>
                      <ListItem className={styleClasses.listItem}>
                        <ListItemIcon>
                          <FiberManualRecordIcon
                            className={styleClasses.listDot}
                          />
                        </ListItemIcon>
                        <Typography className={styleClasses.listText}>
                          Dr Kasun de Zoysa (Director, SCoRe Lab, University of
                          Colombo School of Computing, Sri Lanka)
                        </Typography>
                      </ListItem>
                    </List>
                  </div>

                  <div className={styleClasses.teamContainer}>
                    <Typography variant="h1" className={styleClasses.teamTitle}>
                      Team Members
                    </Typography>

                    <List dense={true}>
                      {temMembers.map((i) => (
                        <ListItem key={i} className={styleClasses.listItem}>
                          <ListItemIcon>
                            <FiberManualRecordIcon
                              className={styleClasses.listDot}
                            />
                          </ListItemIcon>
                          <Typography className={styleClasses.listText}>
                            {i}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </div>

                <div className={styleClasses.section}>
                  <Typography
                    variant="h1"
                    className={styleClasses.sectionTitle}
                  >
                    Contact Us
                  </Typography>
                  <Divider className={styleClasses.divider} />

                  <div>
                    <Typography className={styleClasses.textContainer}>
                      <EmailIcon className={styleClasses.emailIcon} />
                      Dr Thushari Atapattu
                    </Typography>

                    <Typography className={styleClasses.emailContainer}>
                      (
                      <span className={styleClasses.contact}>
                        thushari.atapattu[At]adelaide[Dot]edu[Dot]au
                      </span>
                      )
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={2}></Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default AboutUs;

/**
 *
 */
