import { makeStyles } from "@material-ui/core/styles";

export const indexStyles = makeStyles((theme) => ({
  wrapper: {
    height: "90vh",
    backgroundColor: theme.palette.DARK_BLUE,
  },
  container: {
    backgroundColor: theme.palette.DARK_BLUE,
    paddingTop: "100px",
    paddingBottom: "150px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "20px",
      paddingBottom: "50px",
    },
  },
  flexContainer: {
    display: "flex",
    gap: "50px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mainText: {
    color: "#fff",
    fontSize: "35px",
    marginBottom: "20px",
    lineHeight: "38px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "30px",
      lineHeight: "35px",
      marginBottom: "10px",
    },
  },
  analysis: {
    color: theme.palette.DARK_YELLOW,
  },
  subText: {
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  brain: {
    height: "300px",
    [theme.breakpoints.down("sm")]: {
      height: "250px",
    },
  },
  btnContainer: {
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  agreeBtn: {
    color: `${theme.palette.DARK_BLUE}`,
    backgroundColor: theme.palette.DARK_YELLOW,
    border: `2px solid ${theme.palette.DARK_YELLOW}`,
    borderRadius: "50px",
    transition: "0.5s",
    padding: "3px 15px",
    marginRight: "15px",
    "&:hover": {
      color: theme.palette.DARK_YELLOW,
      backgroundColor: theme.palette.DARK_BLUE,
      transition: "0.5s",
      padding: "3px 15px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3px 15px",
      fontSize: "12px",
    },
  },
  readMoreBtn: {
    color: `${theme.palette.DARK_YELLOW}`,
    border: `2px solid ${theme.palette.DARK_YELLOW}`,
    borderRadius: "50px",
    transition: "0.5s",
    padding: "3px 20px",
    "&:hover": {
      color: theme.palette.DARK_BLUE,
      backgroundColor: theme.palette.DARK_YELLOW,
      transition: "0.5s",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3px 15px",
      fontSize: "12px",
    },
  },
}));

export const userPostStyles = makeStyles((theme) => ({
  section2: {
    backgroundColor: theme.palette.LIGHT_GREY,
    paddingTop: "50px",
    paddingBottom: "50px",
  },
  sec2Title: {
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  postField: {
    marginTop: "20px",
    width: "100%",
  },
  sec2BtnContainer: {
    marginTop: "20px",
    marginBottom: "40px",
    display: "flex",
    justifyContent: "flex-end",
  },
  clearBtn: {
    boxShadow: "0px 1px 3px rgba(9, 9, 9, 0.1)",
    borderRadius: "4px",
    transition: "0.3s",
    padding: "3px 20px",
    marginRight: "10px",
    "&:hover": {
      transition: "0.3s",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3px 15px",
      fontSize: "12px",
    },
  },
  analyseBtn: {
    boxShadow: "0px 1px 3px rgba(9, 9, 9, 0.1)",
    color: `${theme.palette.WHITE}`,
    backgroundColor: theme.palette.DARK_BLUE,
    borderRadius: "4px",
    transition: "0.3s",
    padding: "3px 20px",
    "&:hover": {
      backgroundColor: theme.palette.DARK_BLUE_HOVER,
      transition: "0.3s",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3px 15px",
      fontSize: "12px",
    },
  },
}));

export const resultsStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.WHITE,
    boxShadow: "#5390d912 0px 4px 8px",
    border: `1px solid #d9d9d9`,
  },
}));
