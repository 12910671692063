import React from "react";
import { Typography, Button } from "@material-ui/core";
import { multipleChoiceStyles } from "./styles";
import { formatNumber, capitalizeFirstLetter } from "../../../utils/helpers";
import { RadioGroup, FormControl, FormControlLabel } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";
import { BlueRadio } from "../../helpers/RadioButton";
import { GreyTextField } from "../../helpers/CustomTextFields";
import SaveIcon from "@material-ui/icons/Save";
import { useDispatch } from "react-redux";

function MultipleChoiceQuestion(props) {
  const { answers, questionNo, question, answerIndex } = props;
  const { errors, setErrors, submitted, ACTION_TYPE } = props;
  const { isSubmitted, handleSaveProgress } = props;
  const dispatch = useDispatch();

  const styleClasses = multipleChoiceStyles();

  const handleChange = (event) => {
    const value = event.target.value;
    const newAnswers = [...answers];
    const indexOfAnswer = question.answers.findIndex((i) => i === value);

    // Reset to default
    newAnswers[answerIndex]["answers"].map((i) => {
      i["selected"] = false;
      i["value"] = "";
    });
    newAnswers[answerIndex]["answers"][indexOfAnswer]["selected"] = true;
    newAnswers[answerIndex]["answers"][indexOfAnswer]["value"] = value;
    newAnswers[answerIndex]["answers"][indexOfAnswer]["specify"] = "";
    dispatch({
      type: ACTION_TYPE,
      payload: newAnswers,
    });
    const newErrors = [...errors];
    newErrors[answerIndex] = "";
    setErrors(newErrors);
  };

  const handleTextFieldChange = (event, index) => {
    const value = event.target.value;
    const newAnswers = [...answers];
    newAnswers[answerIndex]["answers"][index]["specify"] = value;
    dispatch({
      type: ACTION_TYPE,
      payload: newAnswers,
    });
    const newErrors = [...errors];
    newErrors[questionNo - 1] = "";
    setErrors(newErrors);
  };

  return (
    <>
      <Typography className={styleClasses.title}>
        Question {formatNumber(questionNo)}
      </Typography>

      <div className={styleClasses.content}>
        <Typography className={styleClasses.question}>
          {question.question}
        </Typography>

        <div>
          <FormControl
            error={errors[questionNo - 1]?.length > 0}
            className={styleClasses.formControl}
          >
            <RadioGroup onChange={handleChange}>
              {question.answers?.map((answer, index) => (
                <div key={question["_id"]["$oid"] + index}>
                  <FormControlLabel
                    className={styleClasses.controlLabel}
                    value={answer}
                    control={
                      <BlueRadio
                        className={styleClasses.radio}
                        checked={
                          answers[answerIndex]["answers"][index]["selected"]
                        }
                        disabled={submitted}
                      />
                    }
                    label={
                      <Typography className={styleClasses.radioLabel}>
                        {capitalizeFirstLetter(answer)}
                      </Typography>
                    }
                  />
                  {answer?.includes("Please specify") &&
                    answers[answerIndex]["answers"][index]["selected"] && (
                      <GreyTextField
                        id="answer"
                        multiline
                        className={styleClasses.inputField}
                        InputLabelProps={{
                          className: styleClasses.inputLabelProps,
                        }}
                        value={
                          answers[answerIndex]["answers"][index]["specify"]
                        }
                        variant="outlined"
                        size="small"
                        onChange={(e) => handleTextFieldChange(e, index)}
                        disabled={submitted}
                      />
                    )}
                </div>
              ))}

              <div className={styleClasses.btnContainer}>
                <Button
                  edge="start"
                  className={`${styleClasses.button} ${styleClasses.saveButton}`}
                  color="inherit"
                  variant="contained"
                  disabled={isSubmitted || submitted}
                  onClick={handleSaveProgress}
                >
                  <SaveIcon className={styleClasses.saveIcon} />
                  <Typography className={styleClasses.saveText}>
                    Save Current Progress
                  </Typography>
                </Button>
              </div>
            </RadioGroup>
            {errors[questionNo - 1] && (
              <FormHelperText>{errors[questionNo - 1]}</FormHelperText>
            )}
          </FormControl>
        </div>
      </div>
    </>
  );
}

export default MultipleChoiceQuestion;
