import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Radio } from "@material-ui/core";

export const BlueRadio = withStyles((theme) => ({
  root: {
    "& svg": {
      width: "22px",
      height: "22px",
    },
    "&$checked": {
      color: theme.palette.primary.dark,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checked: {},
}))((props) => <Radio {...props} />);

export const DarkRedRadio = withStyles((theme) => ({
  root: {
    "& svg": {
      width: "22px",
      height: "22px",
    },
    "&$checked": {
      color: "#d92e21",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checked: {},
}))((props) => <Radio {...props} />);

export const RedRadio = withStyles((theme) => ({
  root: {
    "& svg": {
      width: "22px",
      height: "22px",
    },
    "&$checked": {
      color: "#ee6055",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checked: {},
}))((props) => <Radio {...props} />);

export const YellowRadio = withStyles((theme) => ({
  root: {
    "& svg": {
      width: "22px",
      height: "22px",
    },
    "&$checked": {
      color: "#baa654",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checked: {},
}))((props) => <Radio {...props} />);

export const GreenRadio = withStyles((theme) => ({
  root: {
    "& svg": {
      width: "22px",
      height: "22px",
    },
    "&$checked": {
      color: "#549672",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checked: {},
}))((props) => <Radio {...props} />);

export const DarkGreenRadio = withStyles((theme) => ({
  root: {
    "& svg": {
      width: "22px",
      height: "22px",
    },
    "&$checked": {
      color: "#7ec29d",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checked: {},
}))((props) => <Radio {...props} />);