import { makeStyles } from "@material-ui/core/styles";

export const errorStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "65vh",
  },
  errorMsg: {
    marginLeft: "10px",
  },
}));

export const notCompleteStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: "17px",
    marginRight: "5px",
  },
}));
