import React from "react";
import { useMediaQuery } from "react-responsive";
import DesktopNavBar from "./DesktopNavBar";
import MobileNavBar from "./MobileNavBar";

function MainNavBar(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return <>{isTabletOrMobile ? <MobileNavBar /> : <DesktopNavBar />}</>;
}

export default MainNavBar;
