import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { indexStyles } from "./styles";
import { useHistory, useParams } from "react-router-dom";
import Percentage from "./Percentage";
import PredictionChart from "./PredictionChart";
import Progress from "./Progress";
import { useSelector, useDispatch } from "react-redux";
import { BACKEND_URL } from "../../../config";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Error from "../Error/Error";
import NotComplete from "../Error/NotComplete";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";
import * as actions from "../../../store/actions/actionTypes";
import NextPost from "./NextPost";

function PostQuestionnaire({ setAnalysisCompleted }) {
  const styleClasses = indexStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { userAuthData } = useSelector((state) => state.auth);
  const { answers } = useSelector((state) => state.analysis);
  const { question } = useSelector((state) => state.analysis);

  const { questionNo } = useParams();
  const handleAuthFail = useHandleAuthFail();

  const [isLoadingQ, setIsLoadingQ] = useState(true);
  const [isLoadingA, setIsLoadingA] = useState(true);
  const [submitted, setSubmitted] = useState(null);
  const [preQCompleted, setPreQCompleted] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    retrieveAnalysisAnswers();
  }, []);

  useEffect(() => {
    retrieveAnalysisQuestion();
  }, [questionNo, preQCompleted]);

  const retrieveAnalysisAnswers = async () => {
    if (userAuthData.id) {
      setIsError(false);
      setIsLoadingA(true);
      try {
        const response = await axios.get(
          `${BACKEND_URL}/post-analysis/answers/${userAuthData.id}`
        );

        if (response.data.success) {
          dispatch({
            type: actions.SET_ANALYSIS_ANSWERS,
            payload: response.data.answers,
          });
          setSubmitted(response.data.submitted);
          setIsLoadingA(false);
          setPreQCompleted(true);
        } else {
          setIsLoadingA(false);
          setIsLoadingQ(false);
          setPreQCompleted(false);
          setSubmitted(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleAuthFail();
          return;
        }
        setIsLoadingA(false);
        setIsLoadingQ(false);
        setIsError(true);
      }
    }
  };

  const retrieveAnalysisQuestion = async () => {
    if (questionNo && preQCompleted && !isError) {
      setIsError(false);
      setIsLoadingQ(true);
      try {
        const response = await axios.get(
          `${BACKEND_URL}/post-analysis/questions/${questionNo}`
        );
        if (response.data.success) {
          dispatch({
            type: actions.SET_ANALYSIS_QUESTION,
            payload: response.data.question,
          });
          dispatch({
            type: actions.SET_SUMMARY_AGREED,
            payload: answers[questionNo - 1]?.summaryAgreed,
          });
          dispatch({
            type: actions.SET_SUMMARY_COMMENT,
            payload: answers[questionNo - 1]?.summaryComment,
          });
          dispatch({
            type: actions.SET_CHART_AGREED,
            payload: answers[questionNo - 1]?.chartAgreed,
          });
          dispatch({
            type: actions.SET_CHART_COMMENT,
            payload: answers[questionNo - 1]?.chartComment,
          });
          setIsLoadingQ(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleAuthFail();
          return;
        }
        setIsLoadingQ(false);
        setIsError(true);
      }
    }
  };

  const goTAboutStudy = () => {
    history.push({
      pathname: `/pilot-study/description`,
      state: {
        activePage: 1,
      },
    });
  };

  const displayAnswers = () => {
    if (isLoadingA) {
      return (
        <div className={styleClasses.loadingRoot}>
          <CircularProgress></CircularProgress>
        </div>
      );
    } else if (isError) {
      return <Error />;
    } else if (preQCompleted === false) {
      return (
        <NotComplete
          msg={
            "Please submit the pre-questionnaire before moving onto the post analysis section."
          }
        />
      );
    } else if (answers?.length > 0) {
      return (
        <div>
          <Progress />
        </div>
      );
    }
  };

  const displayQuestion = () => {
    if (isLoadingQ) {
      return (
        <div className={styleClasses.loadingRoot}>
          <CircularProgress></CircularProgress>
        </div>
      );
    } else if (isError) {
      return;
    } else if (Object.keys(question).length !== 0) {
      return (
        <div>
          <Typography className={styleClasses.secTitle}>
            Social media post
          </Typography>
          <Divider className={styleClasses.divider} />

          <div className={styleClasses.postContainer}>
            <Typography className={styleClasses.post}>
              {question.post}
            </Typography>
          </div>
          
          <div>
            <Typography className={styleClasses.secTitle}>
              Prediction Summary
            </Typography>
            <Divider className={styleClasses.divider} />

            <Percentage
              submitted={submitted}
              setIsError={setIsError}
              setAnalysisCompleted={setAnalysisCompleted}
            />
          </div>

          {question?.relevant && (
            <div className={styleClasses.chartContainer}>
              <Typography className={styleClasses.secTitle}>
                Detailed Chart
              </Typography>
              <Divider className={styleClasses.divider} />

              <PredictionChart
                submitted={submitted}
                setIsError={setIsError}
                setAnalysisCompleted={setAnalysisCompleted}
              />
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <>
      <div>
        {preQCompleted && (
          <div className={styleClasses.titleContainer}>
            <div className={styleClasses.container}>
              <Typography className={styleClasses.mainTitle}>
                InnerAwareness Prediction
              </Typography>
              <Typography className={styleClasses.greyText}>
                The requirement of this task is to evaluate the predictions made
                by InnerAwareness tool. It is mandatory to evaluate all the
                posts. Please see instructions '
                <span
                  className={styleClasses.linkToAboutStudy}
                  onClick={goTAboutStudy}
                >
                  Part 2 - research study
                </span>
                ' for more information ( Estimated time - maximum 2 hours )
              </Typography>
            </div>
          </div>
        )}

        <div className={styleClasses.paperContainer}>
          <div className={styleClasses.container}>
            {displayAnswers()}
            {displayQuestion()}
            <NextPost
              submitted={submitted}
              setIsError={setIsError}
              setSubmitted={setSubmitted}
              setAnalysisCompleted={setAnalysisCompleted}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PostQuestionnaire;
